import React from "react";
import { Button, Grid, Typography } from "@mui/material";
import { useWidth } from "../Context/WidthContext";
import { motion } from "framer-motion";
import Footer from "../Components/Footer";
import NavBarSecond from "../Components/NavBar";
import studentImage from "../Images/StudentProgramPage/studentImage.jpeg";


const StudentProgram = () => {
    const { width } = useWidth();

    // Forces the page to start at the top if the page is loaded
    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    return (
		<div style={{position: "absolute", top: "0", left: "0", width: "100%", overflowX: "hidden", backgroundColor: "#ECF3FF"}}>
            {/* Information Section Big Screen */}
            {width > 1000 ? 
                <Grid container sx={{marginTop: "195px", display: "flex", alignItems: "center", paddingLeft: width > 1700 ? "10%" : "6%", paddingRight: width > 1700 ? "10%" : "6%"}}>
                    <Grid item xs={6}>
                        <Typography variant={width > 1500 ? "h5" : width > 1200 ? "h6" : "subtitle1"} sx={{fontFamily: "Montserrat", color: "#8495F2"}}>
                            Special discounts for University Students!
                        </Typography>
                        <Typography variant={width > 1500 ? "h3" : width > 1200 ? "h4" : "h5"} sx={{fontFamily: "Montserrat", fontWeight: "bold", marginTop: "20px"}}>
                            University Student <br /> Discount Program
                        </Typography>
                        <Typography variant={width > 1500 ? "h6" : width > 1200 ? "subtitle1" : "subtitle2"} sx={{fontFamily: "Montserrat", marginTop: "20px"}}>
                            We are apart of a program to provide discounts for university students from participating schools. When our discount is combined with the insurance coverage provided by the university, students can enjoy up to a 30% discount on preventative care.
                        </Typography>
                        
                        <Grid container sx={{display: "flex", flexDirection: "row", marginTop: "52px"}}>
                            <Grid item sx={{padding: "2%", borderRadius: "10px"}}>
                                <Typography variant={width > 1200 ? "subtitle1" : "subtitle2"} sx={{fontFamily: "Montserrat"}}>
                                    Discounts up to
                                </Typography>
                                <div style={{display: "flex", flexDirection: "row", alignItems: "flex-end"}}>
                                    <Typography variant={width > 1200 ? "h5" : "h6"} sx={{fontFamily: "Montserrat", marginTop: "10px", color: "#2DCF6C"}}>
                                        30%
                                    </Typography>
                                    <Typography variant={width > 1200 ? "subtitle1" : "subtitle2"} sx={{fontFamily: "Montserrat", marginTop: "10px", marginBottom: width > 1200 ? "" : "2px", marginLeft: "10px"}}>
                                        off
                                    </Typography>
                                </div>
                            </Grid>
                            <div style={{marginLeft: "36px", borderLeft: "1px solid #C9DBEF"}} />
                            <Grid item sx={{marginLeft: "36px", padding: "2%", borderRadius: "10px"}}>
                                <Typography variant={width > 1200 ? "subtitle1" : "subtitle2"} sx={{fontFamily: "Montserrat"}}>
                                    Includes
                                </Typography>
                                <div style={{display: "flex", flexDirection: "row", alignItems: "flex-end"}}>
                                    <Typography variant={width > 1200 ? "h5" : "h6"} sx={{fontFamily: "Montserrat", marginTop: "10px", color: "#8495F2"}}>
                                        9+
                                    </Typography>
                                    <Typography variant={width > 1200 ? "subtitle1" : "subtitle2"} sx={{fontFamily: "Montserrat", marginTop: "10px", marginBottom: width > 1200 ? "" : "2px", marginLeft: "10px"}}>
                                        Universities
                                    </Typography>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={6} sx={{display: "flex", justifyContent: "flex-end"}}>
                        <img src={studentImage} alt="StudentImage" height="90%" width="90%" style={{borderRadius: "10px", display: "block"}} /> 
                    </Grid>
                </Grid>
            // Information Section Mobile
            :
                <Grid container sx={{paddingLeft: "4%", paddingRight: "4%", marginTop: width > 600 ? "100px" : "90px"}}>
                    <Grid item xs={12} sx={{paddingLeft: width > 600 ? "10%" : "", paddingRight: width > 600 ? "10%" : ""}}>
                        <div style={{backgroundColor: width > 600 ? "" : "#7a5cf8", padding: width > 600 ? "" : width > 500 ? "4%" : "5%", borderRadius: width > 600 ? "" : "10px"}}>
                            <Typography align="center" variant="subtitle1" sx={{fontFamily: "Montserrat", color: width > 600 ? "#8495F2" : "#F4D872"}}>
                                Special discounts for University Students!
                            </Typography>
                            <Typography align="center" variant="h5" sx={{fontFamily: "Montserrat", fontWeight: "bold", marginTop: "20px", color: width > 600 ? "" : "white"}}>
                                University Student Discount Program
                            </Typography>
                            <Typography align="center" variant="subtitle2" sx={{fontFamily: "Montserrat", marginTop: "20px", color: width > 600 ? "" : "white"}}>
                                We are apart of a program to provide discounts for university students from participating schools. When our discount is combined with the insurance coverage provided by the university, students can enjoy up to a 30% discount on preventative care.
                            </Typography>
                        </div>

                        <Grid container sx={{display: "flex", flexDirection: "row", justifyContent: "center", marginTop: width > 600 ? "25px" : "30px"}}>
                            <Grid item xs={width <= 600 && 5.5} sx={{padding: width > 600 ? "2%" : "4%", borderRadius: "10px", backgroundColor: width > 600 ? "" : "#D2E3FF"}}>
                                <Typography variant={width > 1200 ? "subtitle1" : "subtitle2"} sx={{fontFamily: "Montserrat"}}>
                                    Discounts up to
                                </Typography>
                                <div style={{display: "flex", flexDirection: "row", alignItems: "flex-end"}}>
                                    <Typography variant={width > 1200 ? "h5" : "h6"} sx={{fontFamily: "Montserrat", marginTop: "10px", color: width > 600 ? "#2DCF6C" : "#8495F2"}}>
                                        30%
                                    </Typography>
                                    <Typography variant={width > 1200 ? "subtitle1" : "subtitle2"} sx={{fontFamily: "Montserrat", marginTop: "10px", marginBottom: width > 1200 ? "" : "2px", marginLeft: "10px"}}>
                                        off
                                    </Typography>
                                </div>
                            </Grid>
                            {width > 600 && <div style={{marginLeft: "36px", borderLeft: "1px solid #C9DBEF"}} />}
                            {width <= 600 && <Grid item xs={1} />}
                            <Grid item xs={width <= 600 && 5.5} sx={{marginLeft: width > 600 ? "36px" : "", padding: width > 600 ? "2%" : "4%", borderRadius: "10px", backgroundColor: width > 600 ? "" : "#D2E3FF"}}>
                                <Typography variant={width > 1200 ? "subtitle1" : "subtitle2"} sx={{fontFamily: "Montserrat"}}>
                                    Includes
                                </Typography>
                                <div style={{display: "flex", flexDirection: "row", alignItems: "flex-end"}}>
                                    <Typography variant={width > 1200 ? "h5" : "h6"} sx={{fontFamily: "Montserrat", marginTop: "10px", color: "#8495F2"}}>
                                        9+
                                    </Typography>
                                    <Typography variant={width > 1200 ? "subtitle1" : "subtitle2"} sx={{fontFamily: "Montserrat", marginTop: "10px", marginBottom: width > 1200 ? "" : "2px", marginLeft: "10px"}}>
                                        Universities
                                    </Typography>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} sx={{marginTop: "30px", marginBottom: width > 600 ? "60px" : ""}}>
                        {width > 600 ?
                            <img src={studentImage} alt="StudentImage" height="100%" width="100%" style={{borderRadius: "10px"}} /> 
                        :
                            <img src={studentImage} alt="StudentImage" height="100%" width="100%" style={{borderRadius: "10px"}} /> 
                        }
                    </Grid>
                </Grid>
            }



            {/* School Section Full Screen */}
            {width > 600 ? 
                <div style={{marginTop: width > 1000 ? "165px" : "", backgroundColor: "#3E50B5", paddingLeft: width > 1200 ? "10%" : width > 1000 ? "6%" : width > 800 ? "4%" : "", paddingRight: width > 1200 ? "10%" : width > 1000 ? "6%" : width > 800 ? "4%" : ""}}>
                    <br />

                    <Typography variant={width > 1500 ? "h5" : width > 1200 ? "h6" : "subtitle1"} align="center" sx={{fontFamily: "Montserrat", marginTop: width > 900 ? "80px" : "60px", color: "white"}}>
                        We provide coverage for the following schools
                    </Typography>
                    <Typography variant={width > 1500 ? "h6" : width > 1200 ? "subtitle1" : "subtitle2"} align="center" sx={{fontFamily: "Montserrat", marginTop: "15px", color: "white"}}>
                        Please select the school you attend for more details about your coverage
                    </Typography>

                    <Grid container sx={{marginTop: "20px", display: "flex", justifyContent: "center"}}>
                        {SchoolArray.map((university, ID) => (
                                <React.Fragment key={ID}>
                                    <Grid component={motion.div} whileHover={{scale: 1.01, transition: {duration: 0.1}}} item xs={3} sx={{marginTop: "20px", marginLeft: ID % 3 === 0 ? "" : "20px", backgroundColor: "#D2E3FF", borderRadius: "10px", display: "flex", justifyContent: "center", alignItems: "center", transition: "0.3s", "&:hover": {backgroundColor: "#AECDFF"}}}>
                                        <Button href={university.link} target="_blank" sx={{padding: "10%", width: "100%", height: "100%", textTransform: "none"}}>
                                            <Typography align="center" variant={width > 1500 ? "h6" : width > 1200 ? "subtitle1" : "subtitle2"} sx={{fontFamily: "Montserrat", fontWeight: "bold", color: "black"}}>
                                                {university.name}
                                            </Typography>
                                        </Button>
                                    </Grid>

                                    {ID % 3 === 2 && <Grid item xs={12} />}
                                </React.Fragment>  
                            ))
                        }
                    </Grid>

                    <Typography variant={width > 1500 ? "h5" : width > 1200 ? "h6" : "subtitle1"} align="center" sx={{fontFamily: "Montserrat", marginTop: "100px", color: "white"}}>
                        For a complete listing of schools and further information, <br />please visit one of the two links below
                    </Typography>
                    
                    <Grid container sx={{marginTop: "30px", display: "flex", justifyContent: "center", marginBottom: width > 900 ? "80px" : "60px"}}>
                        <Grid component={motion.div} whileHover={{scale: 1.01, transition: {duration: 0.1}}} item xs={width > 900 ? 2 : 3} sx={{backgroundColor: "#49b695", borderRadius: "10px", display: "flex", justifyContent: "center", alignItems: "center", transition: "0.3s", "&:hover": {backgroundColor: "#2BB48A"}}}>
                            <Button href="http://www.studentcare.ca/" target="_blank" sx={{width: "100%", height: "100%", padding: "10%", textTransform: "none"}}>
                                <Typography variant={width > 1500 ? "h6" : width > 1200 ? "subtitle1" : "subtitle2"} align="center" sx={{fontFamily: "Montserrat", color: "white"}}>
                                    Student Care Network
                                </Typography>
                            </Button>
                        </Grid>

                        <Grid component={motion.div} whileHover={{scale: 1.01, transition: {duration: 0.1}}} item xs={width > 900 ? 2 : 3} sx={{marginLeft: "40px", backgroundColor: "#F18185", borderRadius: "10px", display: "flex", justifyContent: "center", alignItems: "center", transition: "0.3s", "&:hover": {backgroundColor: "#F06E73"}}}>
                            <Button href="https://www.mystudentplan.ca/" target="_blank" sx={{width: "100%", height: "100%", padding: "10%", textTransform: "none"}}>
                                <Typography variant={width > 1500 ? "h6" : width > 1200 ? "subtitle1" : "subtitle2"} align="center" sx={{fontFamily: "Montserrat", color: "white"}}>
                                    My Student Plan
                                </Typography>
                            </Button>
                        </Grid>
                    </Grid>

                    <br />
                </div>
            // School Section Mobile
            :
                <Grid container sx={{paddingLeft: "4%", paddingRight: "4%", marginTop: "30px", marginBottom: "30px"}}>
                    <Grid item xs={12} sx={{padding: "4%", borderRadius: "10px", backgroundColor: "#3E50B5"}}>
                        <div style={{paddingLeft: "1%", paddingRight: "1%"}}>
                            <Typography variant="subtitle1" align="center" sx={{fontFamily: "Montserrat", marginTop: "20px", color: "white"}}>
                                We provide coverage for the following schools
                            </Typography>
                            <Typography variant="subtitle2" align="center" sx={{fontFamily: "Montserrat", marginTop: "15px", color: "white"}}>
                                Please select the school you attend for more details about your coverage
                            </Typography>
                        </div>

                        <Grid container sx={{marginTop: "15px", display: "flex", justifyContent: "center", marginBottom: "20px"}}>
                            {SchoolArray.map((university, ID) => (
                                    <React.Fragment key={ID}>
                                        <Grid component={motion.div} whileHover={{scale: 1.01, transition: {duration: 0.1}}} item xs={3.5} sx={{marginTop: "20px", marginLeft: ID % 3 === 0 ? "" : "20px", backgroundColor: "#D2E3FF", borderRadius: "10px", display: "flex", justifyContent: "center", alignItems: "center", transition: "0.3s", "&:hover": {backgroundColor: "#AECDFF"}}}>
                                            <Button href={university.link} target="_blank" sx={{padding: width > 500 ? "16%" : "20%", width: "100%", height: "100%", textTransform: "none"}}>
                                                <Typography align="center" variant="subtitle2" sx={{fontFamily: "Montserrat", fontWeight: "", color: "black"}}>
                                                    {university.name}
                                                </Typography>
                                            </Button>
                                        </Grid>

                                        {ID % 3 === 2 && <Grid item xs={12} />}
                                    </React.Fragment>
                                ))
                            }
                        </Grid>
                    </Grid>

                    <Grid item xs={12} sx={{padding: "4%", borderRadius: "10px", backgroundColor: "#3E50B5", marginTop: "30px"}}>
                        <div style={{paddingLeft: "1%", paddingRight: "1%"}}>
                            <Typography variant="subtitle1" align="center" sx={{fontFamily: "Montserrat", marginTop: "20px", color: "white"}}>
                                For a complete listing of schools and further information, <br />please visit one of the two links below
                            </Typography>
                        </div>

                        <Grid container sx={{marginTop: "25px", display: "flex", justifyContent: "center", marginBottom: "20px"}}>
                            <Grid component={motion.div} whileHover={{scale: 1.01, transition: {duration: 0.1}}} item xs={5} sx={{backgroundColor: "#49b695", borderRadius: "10px", display: "flex", justifyContent: "center", alignItems: "center", transition: "0.3s", "&:hover": {backgroundColor: "#2BB48A"}}}>
                                <Button href="http://www.studentcare.ca/" target="_blank" sx={{width: "100%", height: "100%", padding: width > 500 ? "10%" : "12%", textTransform: "none"}}>
                                    <Typography variant={width > 1500 ? "h6" : width > 1200 ? "subtitle1" : "subtitle2"} align="center" sx={{fontFamily: "Montserrat", color: "white"}}>
                                        Student Care Network
                                    </Typography>
                                </Button>
                            </Grid>

                            <Grid component={motion.div} whileHover={{scale: 1.01, transition: {duration: 0.1}}} item xs={5} sx={{marginLeft: "30px", backgroundColor: "#F18185", borderRadius: "10px", display: "flex", justifyContent: "center", alignItems: "center", transition: "0.3s", "&:hover": {backgroundColor: "#F06E73"}}}>
                                <Button href="https://www.mystudentplan.ca/" target="_blank" sx={{width: "100%", height: "100%", padding: width > 500 ? "10%" : "12%", textTransform: "none"}}>
                                    <Typography variant={width > 1500 ? "h6" : width > 1200 ? "subtitle1" : "subtitle2"} align="center" sx={{fontFamily: "Montserrat", color: "white"}}>
                                        My Student Plan
                                    </Typography>
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            }



            {/* NavBar and Footer Sections */}
            <NavBarSecond />
            <Footer />
        </div>
    )
}

export default StudentProgram;

const SchoolArray = [
    { name: "Conestoga College", link: "http://studentcare.ca/rte/en/IHaveAPlan_CSI_Dental_DentalCoverage" },
    { name: "McMaster University", link: "https://msumcmaster.ca/info/health-dental-insurance/dental-plan/" },
    { name: "York University", link: "http://www.yfs.ca/healthplan" },
    { name: "Queen's University", link: "https://www.queensu.ca/studentwellness/medical/health-insurance" },
    { name: "Sheridan College", link: "http://studentcare.ca/rte/en/SheridanCollegeSU_Dental_DentalCoverage" },
    { name: "University of Guelph", link: "https://www.mystudentplan.ca/uofguelph/en/mybenefits" },
    { name: "University of Toronto", link: "http://studentcare.ca/rte/en/IHaveAPlan_UTSU_Dental_DentalCoverage" },
    { name: "University of Waterloo", link: "http://www.studentcare.ca/rte/en/UniversityofWaterlooundergraduatestudentsFEDS_Dental_DentalCoverage" },
    { name: "Wilfrid Laurier University", link: "https://students.wlu.ca/wellness-and-recreation/health-and-wellness/insurance-and-health-plans/index.html" },
]
