import React from "react";
import { useWidth } from "../Context/WidthContext";
import { useNavigate } from "react-router-dom";
import { Button, Grid, IconButton, Typography } from "@mui/material";
import NavBarSecond from "../Components/NavBar";
import GoogleMaps from "../Components/GoogleMaps";
import Footer from "../Components/Footer";
import PhoneIcon from '@mui/icons-material/Phone';
import FaxIcon from '@mui/icons-material/Fax';
import EmailIcon from '@mui/icons-material/Email';
import FacebookIcon from '@mui/icons-material/Facebook';
import image from "../Images/ContactPage/image.jpg";
import locationImageWEBP from "../Images/ContactPage/locationImage.webp";
import locationImageJPG from "../Images/ContactPage/locationImage.jpg";
import visaWEBP from "../Images/ContactPage/visa.webp";
import visajpg from "../Images/ContactPage/visa.jpg";
import mastercardWEBP from "../Images/ContactPage/mastercard.webp";
import mastercardPNG from "../Images/ContactPage/mastercard.png";
import interacWEBP from "../Images/ContactPage/interac.webp";
import interacPNG from "../Images/ContactPage/interac.png";


const Contact = () => {
    const { width } = useWidth();
    const navigate = useNavigate();

    // Forces the page to start at the top if the page is loaded
    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    return (
		<div style={{position: "absolute", top: "0", left: "0", width: "100%", overflowX: "hidden"}}>
            {/* Landing Section */}
            <Grid container sx={{marginTop: width > 600 ? "100px" : "90px", display: "flex", alignItems: "center", justifyContent: "center"}}>
                {width > 600 ? 
                    <>
                        <Grid item xs={width > 1000 ? 4 : 12} sx={{display: "flex", justifyContent: "center", flexDirection: "column", backgroundColor: "", padding: width > 1500 ? "4%" : "4% 0 4% 0%", borderRadius: "10px"}}>
                            <Typography align={width > 1000 ? "left" : "center"} variant={width > 1600 ? "h4" : width > 1100 ? "h5" : "h6"} sx={{fontFamily: "Montserrat", fontWeight: "bold"}}>
                                Contact Us
                            </Typography>
                            
                            <div style={{marginTop: width > 1400 ? "25px" : "20px", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: width <= 1000 && "center"}}>
                                <IconButton href="tel:(519) 821 - 6668" sx={{padding: "0", "&:hover": {backgroundColor: "transparent"}}}>
                                    <PhoneIcon sx={{color: "#fa6875", "&:hover": {color: "#FE4252"}}} />
                                </IconButton>
                                <IconButton href="tel:(519) 821 - 6668" sx={{padding: "0", marginLeft: "20px", "&:hover": {backgroundColor: "transparent", textDecoration: "underline"}}}>
                                    <Typography align="center" variant={width > 1600 ? "h6" : width > 1100 ? "subtitle1" : "subtitle2"} sx={{fontFamily: "Montserrat", color: "black"}}>
                                        (519) - 821 - 6668
                                    </Typography>
                                </IconButton>
                            </div>

                            <div style={{marginTop: "20px", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: width <= 1000 && "center"}}>
                                <IconButton href="tel:(519) 821 - 1210" sx={{padding: "0", "&:hover": {backgroundColor: "transparent"}}}>
                                    <FaxIcon sx={{color: "#fa6875", "&:hover": {color: "#FE4252"}}} />
                                </IconButton>
                                <IconButton href="tel:(519) 821 - 1210" sx={{padding: "0", marginLeft: "20px", "&:hover": {backgroundColor: "transparent", textDecoration: "underline"}}}>
                                    <Typography align="center" variant={width > 1600 ? "h6" : width > 1100 ? "subtitle1" : "subtitle2"} sx={{fontFamily: "Montserrat", color: "black"}}>
                                        (519) - 821 - 1210
                                    </Typography>
                                </IconButton>
                            </div>

                            <div style={{marginTop: "20px", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: width <= 1000 && "center"}}>
                                <IconButton href="mailto:guelphdental@hotmail.com" sx={{padding: "0", "&:hover": {backgroundColor: "transparent"}}}>
                                    <EmailIcon sx={{color: "#fa6875", "&:hover": {color: "#FE4252"}}} />
                                </IconButton>
                                <IconButton href="mailto:guelphdental@hotmail.com" sx={{padding: "0", marginLeft: "20px", "&:hover": {backgroundColor: "transparent", textDecoration: "underline"}}}>
                                    <Typography align="center" variant={width > 1600 ? "h6" : width > 1100 ? "subtitle1" : "subtitle2"} sx={{fontFamily: "Montserrat", color: "black"}}>
                                        guelphdental@hotmail.com
                                    </Typography>
                                </IconButton>
                            </div>

                            <div style={{marginTop: "20px", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: width <= 1000 && "center"}}>
                                <IconButton href="https://www.facebook.com/guelph.dental.9" target="_blank" sx={{padding: "0", "&:hover": {backgroundColor: "transparent"}}}>
                                    <FacebookIcon sx={{color: "#fa6875", "&:hover": {color: "#FE4252"}}} />
                                </IconButton>
                                <IconButton href="https://www.facebook.com/guelph.dental.9" target="_blank" sx={{padding: "0", marginLeft: "20px", "&:hover": {backgroundColor: "transparent", textDecoration: "underline"}}}>
                                    <Typography align="center" variant={width > 1600 ? "h6" : width > 1100 ? "subtitle1" : "subtitle2"} sx={{fontFamily: "Montserrat", color: "black"}}>
                                        Add us as a friend on Facebook!
                                    </Typography>
                                </IconButton>
                            </div>

                            {width > 1400 &&
                                <div style={{marginTop: "30px"}}>
                                    <Button onClick={() => {navigate("/appointment");}} variant="contained" sx={{textTransform: "none", transition: "0.2s", borderRadius: "5px", fontFamily: "Montserrat", fontSize: width > 1600 ? "16px" : width > 1100 ? "14px" : "12px", fontWeight: "bold", color: "white", backgroundColor: "#3E50B5", "&:hover": {backgroundColor: "#263CBC"}}}>
                                        Book an Appointment
                                    </Button>
                                    <Button href="tel:(519) 821 - 6668" variant="standard" sx={{textTransform: "none", marginLeft: width > 1400 ? "28px" : "", fontSize: width > 1600 ? "16px" : width > 1100 ? "14px" : "12px", fontFamily: "Montserrat", fontWeight: "bold", border: "2px solid #3E50B5", color: "#3E50B5", borderRadius: "5px"}}>
                                        Call Us
                                    </Button>
                                </div>
                            }
                        </Grid>
                        <Grid item xs={width > 1000 ? 5.5 : 12} sx={{marginLeft: width > 1300 ? "100px" : width > 1000 ? "60px" : "", display: width > 1000 ? "flex" : "flex", justifyContent: width > 1000 ? "flex-end" : "center"}}>
                            <img src={image} alt="LandingImage" height={width > 1000 ? "90%" : width > 700 ? "70%" : "100%"} width={width > 1000 ? "90%" : width > 700 ? "70%" : "100%"} style={{borderRadius: width > 1000 ? "10px" : "", marginTop: width > 1000 ? "15px" : ""}} />
                        </Grid>
                    </>
                :    
                // Mobile Landing Section
                    <Grid container sx={{paddingLeft: "4%", paddingRight: "4%"}}>
                        <Grid item xs={12} sx={{backgroundColor: "#5773F3", borderRadius: "10px", padding: width > 500 ? "4%" : "6%"}}>
                            <Typography align="center" variant="h6" sx={{fontFamily: "Montserrat", fontWeight: "bold", color: "white"}}>
                                Contact Us
                            </Typography>
                            
                            <div style={{marginTop: "20px", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center"}}>
                                <IconButton href="tel:(519) 821 - 6668" sx={{padding: "0"}}>
                                    <PhoneIcon sx={{color: "#fdc854"}} />
                                </IconButton>
                                <IconButton href="tel:(519) 821 - 6668" sx={{padding: "0", marginLeft: "20px"}}>
                                    <Typography align="center" variant="subtitle2" sx={{fontFamily: "Montserrat", color: "white"}}>
                                        (519) - 821 - 6668
                                    </Typography>
                                </IconButton>
                            </div>

                            <div style={{marginTop: "20px", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center"}}>
                                <IconButton href="tel:(519) 821 - 1210" sx={{padding: "0"}}>
                                    <FaxIcon sx={{color: "#fdc854"}} />
                                </IconButton>
                                <IconButton href="tel:(519) 821 - 1210" sx={{padding: "0", marginLeft: "20px"}}>
                                    <Typography align="center" variant="subtitle2" sx={{fontFamily: "Montserrat", color: "white"}}>
                                        (519) - 821 - 1210
                                    </Typography>
                                </IconButton>
                            </div>

                            <div style={{marginTop: "20px", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center"}}>
                                <IconButton href="mailto:guelphdental@hotmail.com" sx={{padding: "0"}}>
                                    <EmailIcon sx={{color: "#fdc854"}} />
                                </IconButton>
                                <IconButton href="mailto:guelphdental@hotmail.com" sx={{padding: "0", marginLeft: "20px"}}>
                                    <Typography align="center" variant="subtitle2" sx={{fontFamily: "Montserrat", color: "white"}}>
                                        guelphdental@hotmail.com
                                    </Typography>
                                </IconButton>
                            </div>

                            <div style={{marginTop: "20px", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center"}}>
                                <IconButton href="https://www.facebook.com/guelph.dental.9" target="_blank" sx={{padding: "0"}}>
                                    <FacebookIcon sx={{color: "#fdc854"}} />
                                </IconButton>
                                <IconButton href="https://www.facebook.com/guelph.dental.9" target="_blank" sx={{padding: "0", marginLeft: "20px"}}>
                                    <Typography align="center" variant="subtitle2" sx={{fontFamily: "Montserrat", color: "white"}}>
                                        Add us as a friend on Facebook!
                                    </Typography>
                                </IconButton>
                            </div>
                        </Grid>
                        <Grid item xs={12} sx={{marginTop: "30px"}}>
                            <img src={image} alt="LandingImage" height="100%" width="100%" style={{borderRadius: "10px"}} />
                        </Grid>
                    </Grid>
                }



                {/* Address Section Full */}
                {width > 600 ? 
                    <>
                        {width > 1000 && 
                            <Grid item xs={6} sx={{marginTop: "130px"}}>
                                <picture style={{height: "100%"}}>
                                    <source srcSet={locationImageWEBP} type="image/webp" />
                                    <source srcSet={locationImageJPG} type="image/jpg" /> 
                                    <img src={locationImageJPG} alt="GuelphDentalLocationImage" height="100%" width="100%" style={{borderRadius: "0 5px 5px 0"}} />
                                </picture>
                            </Grid>
                        }
                        <Grid item xs={width > 1000 ? 6 : 12} sx={{display: "flex", alignItems: "flex-start", justifyContent: "center", flexDirection: "column", marginTop: width > 1200 ? "110px" : width > 1000 ? "130px" : "100px"}}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Typography align={width > 1000 ? "left" : "center"} variant={width > 1600 ? "subtitle1" : "subtitle2"} sx={{fontFamily: "Montserrat", fontWeight: "bold", color: "#B0A8F6", marginLeft: width > 1400 ? "140px" : width > 1000 ? "100px" : ""}}>
                                        ADDRESS
                                    </Typography>
                                    <Typography align={width > 1000 ? "left" : "center"} variant={width > 1600 ? "h5" : width > 1100 ? "h6" : "subtitle1"} sx={{fontFamily: "Montserrat", fontWeight: "bold", marginLeft: width > 1400 ? "140px" : width > 1000 ? "100px" : "", marginTop: "10px"}}>
                                        Guelph Dental
                                    </Typography>
                                    <div style={{marginTop: "10px"}}>
                                        <Typography align={width > 1000 ? "left" : "center"} variant={width > 1600 ? "h6" : width > 1100 ? "subtitle1" : "subtitle2"} sx={{fontFamily: "Montserrat", marginLeft: width > 1400 ? "140px" : width > 1000 ? "100px" : ""}}>
                                            64 Frederick Dr, Suite 103,
                                        </Typography>
                                        <Typography align={width > 1000 ? "left" : "center"} variant={width > 1600 ? "h6" : width > 1100 ? "subtitle1" : "subtitle2"} sx={{fontFamily: "Montserrat", marginLeft: width > 1400 ? "140px" : width > 1000 ? "100px" : ""}}>
                                            Guelph, ON, N1L 0P3
                                        </Typography>
                                    </div>
                                    <div style={{marginTop: width > 1400 ? "30px" : "20px"}}>
                                        <Typography align={width > 1000 ? "left" : "center"} variant={width > 1600 ? "h5" : width > 1100 ? "h6" : "subtitle1"} sx={{fontFamily: "Montserrat", fontWeight: "bold", marginLeft: width > 1400 ? "140px" : width > 1000 ? "100px" : "", marginTop: "10px"}}>
                                            Hours
                                        </Typography>
                                        <Typography align={width > 1000 ? "left" : "center"} variant={width > 1600 ? "h6" : width > 1100 ? "subtitle1" : "subtitle2"} sx={{fontFamily: "Montserrat", marginLeft: width > 1400 ? "140px" : width > 1000 ? "100px" : "", marginTop: "10px"}}>
                                            Monday: 9AM - 7PM
                                        </Typography>
                                        <Typography align={width > 1000 ? "left" : "center"} variant={width > 1600 ? "h6" : width > 1100 ? "subtitle1" : "subtitle2"} sx={{fontFamily: "Montserrat", marginLeft: width > 1400 ? "140px" : width > 1000 ? "100px" : "", marginTop: "10px"}}>
                                            Tuesday to Thursday: 9AM - 4PM
                                        </Typography>
                                        <Typography align={width > 1000 ? "left" : "center"} variant={width > 1600 ? "h6" : width > 1100 ? "subtitle1" : "subtitle2"} sx={{fontFamily: "Montserrat", marginLeft: width > 1400 ? "140px" : width > 1000 ? "100px" : "", marginTop: "10px"}}>
                                            Friday: 8AM - 3PM
                                        </Typography>
                                        <Typography align={width > 1000 ? "left" : "center"} variant={width > 1600 ? "h6" : width > 1100 ? "subtitle1" : "subtitle2"} sx={{fontFamily: "Montserrat", marginLeft: width > 1400 ? "140px" : width > 1000 ? "100px" : "", marginTop: "10px"}}>
                                            Every other Saturday: 9AM - 4PM
                                        </Typography>
                                        <Typography align={width > 1000 ? "left" : "center"} variant={width > 1600 ? "h6" : width > 1100 ? "subtitle1" : "subtitle2"} sx={{fontFamily: "Montserrat", fontWeight: "bold", marginLeft: width > 1400 ? "140px" : width > 1000 ? "100px" : "", marginTop: "10px"}}>
                                            Other hours available by appointment
                                        </Typography>
                                        <div style={{display: width > 1000 ? "" : "flex", justifyContent: width > 1100 ? "" : "center"}}>
                                            <Button href="https://www.google.com/maps/dir//64+frederick+drive+guelph+guelph+dental/data=!4m6!4m5!1m1!4e2!1m2!1m1!1s0x882b90087047b911:0xf37a8418ddcc88b1?sa=X&ved=2ahUKEwi19PmWqIv4AhUgnIQIHYiCCw0Q9Rd6BAg4EAQ" target="_blank" variant="contained" sx={{textTransform: "none", transition: "0.2s", borderRadius: "5px", marginLeft: width > 1400 ? "140px" : width > 1000 ? "100px" : "", marginTop: width > 1400 ? "25px" : "20px", padding: "1%", fontFamily: "Montserrat", fontSize: width > 1600 ? "16px" : width > 1100 ? "14px" : "12px", fontWeight: "bold", color: "white", backgroundColor: "#fa6875", "&:hover": {backgroundColor: "#F35563"}}}>
                                                Click here for Directions
                                            </Button>
                                        </div>
                                    </div>

                                </Grid>
                            </Grid>
                        </Grid>
                        {width <= 1000 &&
                            <Grid item xs={12} sx={{marginTop: width > 1000 ? "130px" : "70px"}}>
                                <picture style={{height: "100%"}}>
                                    <source srcSet={locationImageWEBP} type="image/webp" />
                                    <source srcSet={locationImageJPG} type="image/jpg" /> 
                                    <img src={locationImageJPG} alt="GuelphDentalLocationImage" height="100%" width="100%" />
                                </picture>
                            </Grid>
                        }
                    </>
                :
                // Address section Mobile
                    <Grid container sx={{paddingLeft: "4%", paddingRight: "4%", marginTop: "30px"}}>
                        <Grid item xs={12} sx={{backgroundColor: "#6F73D2", borderRadius: "10px", padding: width > 500 ? "4%" : "6%"}}>
                            <Typography align="center" variant="subtitle1" sx={{fontFamily: "Montserrat", fontWeight: "bold", marginLeft: width > 1400 ? "120px" : width > 1000 ? "80px" : "", color: "white"}}>
                                Guelph Dental
                            </Typography>
                            <div style={{marginTop: "10px"}}>
                                <Typography align="center" variant="subtitle2" sx={{fontFamily: "Montserrat", marginLeft: width > 1400 ? "120px" : width > 1000 ? "80px" : "", color: "white"}}>
                                    64 Frederick Dr, Suite 103,
                                </Typography>
                                <Typography align="center" variant="subtitle2" sx={{fontFamily: "Montserrat", marginLeft: width > 1400 ? "120px" : width > 1000 ? "80px" : "", color: "white"}}>
                                    Guelph, ON, N1L 0P3
                                </Typography>
                            </div>
                            <Typography align="center" variant="subtitle1" sx={{fontFamily: "Montserrat", fontWeight: "bold", marginLeft: width > 1400 ? "120px" : width > 1000 ? "80px" : "", marginTop: width > 1400 ? "30px" : "20px", color: "white"}}>
                                Hours
                            </Typography>
                            <Typography align="center" variant="subtitle2" sx={{fontFamily: "Montserrat", marginLeft: width > 1400 ? "120px" : width > 1000 ? "80px" : "", marginTop: "10px", color: "white"}}>
                                Monday: 9AM - 7PM
                            </Typography>
                            <Typography align="center" variant="subtitle2" sx={{fontFamily: "Montserrat", marginLeft: width > 1400 ? "120px" : width > 1000 ? "80px" : "", marginTop: "10px", color: "white"}}>
                                Tuesday to Friday: 9AM - 4PM
                            </Typography>
                            <Typography align="center" variant="subtitle2" sx={{fontFamily: "Montserrat", marginLeft: width > 1400 ? "120px" : width > 1000 ? "80px" : "", marginTop: "10px", color: "white"}}>
                                Every other Saturday: 9AM - 4PM
                            </Typography>
                            <Typography align="center" variant="subtitle2" sx={{fontFamily: "Montserrat", marginLeft: width > 1400 ? "120px" : width > 1000 ? "80px" : "", marginTop: "10px", color: "white"}}>
                                Other hours available by appointment
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{marginTop: "30px"}}>
                            <picture style={{height: "100%"}}>
                                <source srcSet={locationImageWEBP} type="image/webp" />
                                <source srcSet={locationImageJPG} type="image/jpg" /> 
                                <img src={locationImageJPG} alt="GuelphDentalLocationImage" height="100%" width="100%" style={{borderRadius: "10px"}} />
                            </picture>
                        </Grid>
                    </Grid>
                }



                {/* Payments Section */}
                {width > 600 ?
                    <Grid item xs={12} sx={{marginTop: width > 600 ? "110px" : "30px"}}>
                        <Grid container sx={{display: "flex", justifyContent: "center"}}>
                            <Grid item xs={width > 1500 ? 1 : width > 900 ? 2 : 3} sx={{display: "flex", justifyContent: "center", alignItems: "center", borderRight: "1px solid #C9DBEF"}}>
                                <picture style={{height: "100%"}}>
                                    <source srcSet={visaWEBP} type="image/webp" />
                                    <source srcSet={visajpg} type="image/jpg" />
                                    <img src={visajpg} alt="VisaImage" height="100px" width="100px" style={{display: "block"}} />
                                </picture>
                            </Grid>
                            <Grid item xs={width > 1500 ? 1 : width > 900 ? 2 : 3} sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                                <picture style={{height: "100%"}}>
                                    <source srcSet={mastercardWEBP} type="image/webp" />
                                    <source srcSet={mastercardPNG} type="image/jpg" />
                                    <img src={mastercardPNG} alt="MastercardImage" height="100px" width="100px" style={{display: "block"}} />
                                </picture>
                            </Grid>
                            <Grid item xs={width > 1500 ? 1 : width > 900 ? 2 : 3} sx={{display: "flex", justifyContent: "center", alignItems: "center", borderLeft: "1px solid #C9DBEF"}}>
                                <picture style={{height: "100%", display: "flex", justifyContent: "center", alignItems: "center"}}>
                                    <source srcSet={interacWEBP} type="image/webp" />
                                    <source srcSet={interacPNG} type="image/jpg" />
                                    <img src={interacPNG} alt="InteracImage" height="80px" width="80px" />
                                </picture>
                                
                            </Grid>
                        </Grid>
                    </Grid>
                :
                    <Grid item xs={12} sx={{marginTop: "30px", paddingLeft: "4%", paddingRight: "4%"}}>
                        <Grid container sx={{borderRadius: "10px", display: "flex", justifyContent: "center"}}>
                            <Grid item xs={3.5} sx={{borderRadius: "10px", padding: "4%", backgroundColor: "#D2E3FF"}}>
                                <picture style={{height: "100%"}}>
                                    <source srcSet={visaWEBP} type="image/webp" />
                                    <source srcSet={visajpg} type="image/jpg" />
                                    <img src={visajpg} alt="VisaImage" height="100%" width="100%" style={{display: "block"}} />
                                </picture>
                            </Grid>
                            <Grid item xs={0.75} />
                            <Grid item xs={3.5} sx={{borderRadius: "10px", display: "flex", justifyContent: "center", alignItems: "center", padding: "4%", backgroundColor: "#D2E3FF"}}>
                                <picture style={{height: "100%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center"}}>
                                    <source srcSet={interacWEBP} type="image/webp" />
                                    <source srcSet={interacPNG} type="image/jpg" />
                                    <img src={interacPNG} alt="InteracImage" height="80%" width="80%" style={{display: "block"}} />
                                </picture>
                            </Grid>
                            <Grid item xs={0.75} />
                            <Grid item xs={3.5} sx={{borderRadius: "10px", padding: "4%", backgroundColor: "#D2E3FF"}}>
                                <picture style={{height: "100%"}}>
                                    <source srcSet={mastercardWEBP} type="image/webp" />
                                    <source srcSet={mastercardPNG} type="image/jpg" />
                                    <img src={mastercardPNG} alt="MastercardImage" height="100%" width="100%" style={{display: "block"}} />
                                </picture>
                            </Grid>
                        </Grid>
                    </Grid>
                }


                
                {/* Google Map Section */}
                <Grid item xs={12} sx={{marginTop: width > 600 ? "110px" : "30px"}}>
                    <GoogleMaps />
                </Grid>
            </Grid>



            {/* Footer and NavBar section */}
            <Footer />
            <NavBarSecond />
        </div>
    )
}

export default Contact;