import React from "react";
import NavBar from "../Components/NavBar";
import Footer from "../Components/Footer";
import emailjs from "@emailjs/browser";
import { useWidth } from "../Context/WidthContext";
import { Grid, Typography, InputBase, Radio, Button, IconButton, Menu, MenuItem } from "@mui/material";
import CheckIcon from '@mui/icons-material/Check';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import image from "../Images/AppointmentsPage/image.jpg";
import StarIcon from '@mui/icons-material/Star';


const Appointment = () => {
    const { width } = useWidth();

    // Forces the page to start at the top if the page is loaded
    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    // Time Form Section
    const [chosenTime, setChosenTime] = React.useState("");
    const [timeMenu, setTimeMenu] = React.useState(null);
    const openTimeMenu = Boolean(timeMenu);
    
    const timeArray = [
        "8am", "9am", "10am", "11am", "12pm", "1pm", "2pm", "3pm", "4pm", "5pm", "6pm"
    ];

    const openTime = (event) => {
        setTimeMenu(event.currentTarget);
    };

    const timeHandler = (time) => {
        setChosenTime(time);
        setTimeMenu(null);
    };



    // Reason Form Section
    const [chosenReason, setChosenReason] = React.useState("");
    const [reasonMenu, setReasonMenu] = React.useState(null);
    const openReasonMenu = Boolean(reasonMenu);
    
    const reasonArray = [
        "New patient visit", "Regular checkup/cleaning", "Ongoing Treatment", "Cosmetic Procedure", "Other"
    ];

    const openReason = (event) => {
        setReasonMenu(event.currentTarget);
    };

    const reasonHandler = (time) => {
        setChosenReason(time);
        setReasonMenu(null);
    };



    // Existing Patient Form Section
    const [existPatient, setExistPatient] = React.useState(false);
    const [newPatient, setNewPatient] = React.useState(false);

    const existPatientHandler = () => {
        setNewPatient(false);
        setExistPatient(true);
    };

    const newPatientHandler = () => {
        setExistPatient(false);
        setNewPatient(true);
    };



    // Morning/Afternoon Form Section
    const [morning, setMorning] = React.useState(false);
    const [afternoon, setAfternoon] = React.useState(false);

    const morningHandler = () => {
        setAfternoon(false);
        setMorning(true);
    };

    const afternoonHandler = () => {
        setMorning(false);
        setAfternoon(true);
    };



    // Email/Phone Form Section
    const [email, setEmail] = React.useState(false);
    const [phone, setPhone] = React.useState(false);

    const emailHandler = () => {
        setPhone(false);
        setEmail(true);
    };

    const phoneHandler = () => {
        setEmail(false);
        setPhone(true)
    };

    

    // Available Day Form Section
    const [monday, setMonday] = React.useState(false);
    const [tuesday, setTuesday] = React.useState(false);
    const [wednesday, setWednesday] = React.useState(false);
    const [thursday, setThursday] = React.useState(false);
    const [friday, setFriday] = React.useState(false);
    const [saturday, setSaturday] = React.useState(false);

    const mondayHandler = () => {
        if (monday === false) {
            setMonday(true);
        }
        else {
            setMonday(false);
        }
    };

    const tuesdayHandler = () => {
        if (tuesday === false) {
            setTuesday(true);
        }
        else {
            setTuesday(false);
        }
    };

    const wednesdayHandler = () => {
        if (wednesday === false) {
            setWednesday(true);
        }
        else {
            setWednesday(false);
        }
    };

    const thursdayHandler = () => {
        if (thursday === false) {
            setThursday(true);
        }
        else {
            setThursday(false);
        }
    };

    const fridayHandler = () => {
        if (friday === false) {
            setFriday(true);
        }
        else {
            setFriday(false);
        }
    };

    const saturdayHandler = () => {
        if (saturday === false) {
            setSaturday(true);
        }
        else {
            setSaturday(false);
        }
    };


    // First Name/Last Name/Email Address/Phone Number Form Sections
    const [firstName, setFirstName] = React.useState("");
    const [lastName, setLastName] = React.useState("");
    const [emailAddress, setEmailAddress] = React.useState("");
    const [phoneNumber, setPhoneNumber] = React.useState("");

    const handleFirstNameChange = (event) => {
        setFirstName(event.target.value);
    };

    const handleLastNameChange = (event) => {
        setLastName(event.target.value);
    };

    const handleEmailAddressChange = (event) => {
        setEmailAddress(event.target.value);
    }

    const handlePhoneNumberChange = (event) => {
        setPhoneNumber(event.target.value);
    };



    // Clear Section
    const handleClear = () => {
        setAppointmentRequested(false);

        setExistPatient(false);
        setNewPatient(false);

        setMorning(false);
        setAfternoon(false);

        setEmail(false);
        setPhone(false);

        setMonday(false);
        setTuesday(false);
        setWednesday(false);
        setThursday(false);
        setFriday(false);
        setSaturday(false);

        setChosenTime("");
        setChosenReason("");

        setFirstName("");
        setLastName("");
        setEmailAddress("");
        setPhoneNumber("");

        setFirstNameError(false);
        setLastNameError(false);
        setEmailError(false);
        setPhoneError(false);
        setExistingPatientError(false);
    };



    // Input field error checks
    const [firstNameError, setFirstNameError] = React.useState(false);
    const [lastNameError, setLastNameError] = React.useState(false);
    const [emailError, setEmailError] = React.useState(false);
    const [phoneError, setPhoneError] = React.useState(false);
    const [existingPatientError, setExistingPatientError] = React.useState(false);



    // Appointment Request Sent
    const [appointmentRequested, setAppointmentRequested] = React.useState(false);



    // Submit Section
    const handleSubmit = () => {
        let firstNameE = false;
        let lastNameE = false;
        let emailE = false;
        let phoneE = false;
        let existingPatientE = false;
        setAppointmentRequested(false);


        // Error checks
        if (firstName === "") {
            setFirstNameError(true);
            firstNameE = true;
        }
        else {
            setFirstNameError(false);
            firstNameE = false;
        }

        if (lastName === "") { 
            setLastNameError(true);
            lastNameE = true;
        }
        else {
            setLastNameError(false);
            lastNameE = false;
        }

        if (emailAddress === "") {
            setEmailError(true);
            emailE = true;
        }
        else {
            setEmailError(false);
            emailE = false;
        }

        if (phoneNumber === "") {
            setPhoneError(true);
            phoneE = true;
        }
        else {
            setPhoneError(false);
            phoneE = false;
        }

        if (existPatient === false && newPatient === false) {
            setExistingPatientError(true);
            existingPatientE = true;
        }
        else {
            setExistingPatientError(false);
            existingPatientE = false;
        }

        if (firstNameE === false && lastNameE === false && emailE === false && phoneE === false && existingPatientE === false) {
            // Preparing existing patient information to send
            let existingPatientString = "";
            if (existPatient === true) {
                existingPatientString = "Existing Patient";
            }
            else {
                existingPatientString = "New Patient";
            }

            // Preparing days available information to send
            let daysAvailable = "";
            if (monday === false && tuesday === false && wednesday === false && thursday === false && friday === false && saturday === false) { 
                daysAvailable = "Patient did not specify";
            }
            if (monday === true) {
                daysAvailable += "Monday, ";
            }
            if (tuesday === true) {
                daysAvailable += "Tuesday, ";
            }
            if (wednesday === true) {
                daysAvailable += "Wednesday, ";
            }
            if (thursday === true) {
                daysAvailable += "Thursday, ";
            }
            if (friday === true) {
                daysAvailable += "Friday, ";
            }
            if (saturday === true) {
                daysAvailable += "Satuday, ";
            }
            if (daysAvailable !== "Patient did not specify") {
                daysAvailable = daysAvailable.substring(0, daysAvailable.length - 2);
            }



            // Preparing chosen time information to send
            let chosenTimeSubmit = "";
            if (chosenTime === "") {
                chosenTimeSubmit = "Patient did not specify";
            }
            else {
                chosenTimeSubmit = chosenTime;
            }
    
            // Preparing contact method information to send
            let contactMethod = "";
            if (email === false && phone === false) {
                contactMethod = "Patient did not specify";
            }
            else if (email === true) {
                contactMethod = "Email";
            }
            else {
                contactMethod = "Phone";
            }
    
            // Preparing contact time information to send
            let contactTime = "";
            if (morning === false && afternoon === false) {
                contactTime = "Patient did not specify";
            }
            else if (morning === true) {
                contactTime = "Morning";
            }
            else {
                contactTime = "Afternoon";
            }
    
            // Preparing reason for appointment information to submit
            let chosenReasonSubmit = "";
            if (chosenReason === "") {
                chosenReasonSubmit = "Patient did not specify";
            }
            else {
                chosenReasonSubmit = chosenReason;
            }
    
            // Appointment template holding all information to send in the email
            const appointmentTemplate = {
                firstName: firstName,
                lastName: lastName,
                emailAddress: emailAddress,
                phoneNumber: phoneNumber,
                existingPatient: existingPatientString,
    
                daysAvailable: daysAvailable,
                bestTimeAppointment: chosenTimeSubmit,
                contactMethod: contactMethod,
                contactTime: contactTime,
                appointmentReason: chosenReasonSubmit
            }
    
            // Sends the email using EmailJS
            emailjs.send('service_2yfjjdh', 'template_byw3e27', appointmentTemplate, 'IADPkhKYIF2rMQ7W1').then(() => {
                alert(`Your request for an appointment has been successfully sent!\nWe will get in touch with you regarding appointment details as soon as possible.\n\nFor further information please do not hesitate to contact us at:\nguelphdental@hotmail.com\n(519) - 821 - 6668`);
                setAppointmentRequested(true);
            }, () => {
                alert('Your request for an appointment has failed, it may be due to a server error on our end or a connection error on your end, nonetheless please try again in a few minutes!');
            });

            setExistPatient(false);
            setNewPatient(false);
    
            setMorning(false);
            setAfternoon(false);
    
            setEmail(false);
            setPhone(false);
    
            setMonday(false);
            setTuesday(false);
            setWednesday(false);
            setThursday(false);
            setFriday(false);
            setSaturday(false);
    
            setChosenTime("");
            setChosenReason("");
    
            setFirstName("");
            setLastName("");
            setEmailAddress("");
            setPhoneNumber("");
    
            setFirstNameError(false);
            setLastNameError(false);
            setEmailError(false);
            setPhoneError(false);
            setExistingPatientError(false);
        }
    }


    return (
		<div style={{position: "absolute", top: "0", left: "0", width: "100%", overflowX: "hidden", backgroundColor: width > 600 ? "" : "#E5EDFF"}}>
            {/* Landing Banner Full */}
            {width > 600 ? 
                <Grid container sx={{backgroundColor: "#E5EDFF"}}>
                    {width > 1000 ?
                        <Grid item xs={12} sx={{marginTop: "45px", height: "60vh", display: "flex", justifyContent: "center"}}>
                            <div style={{display: "flex", flexDirection: "row"}}>
                                <div style={{display: "flex", justifyContent: "center", flexDirection: "column"}}>
                                    <Typography align={width > 1000 ? "left" : "center"} variant={width > 1500 ? "h4" : width > 1150 ? "h5" : "h6"} sx={{fontFamily: "Montserrat", fontWeight: "bold"}}>
                                        Appointments
                                    </Typography>

                                    <Typography align={width > 1000 ? "left" : "center"} variant={width > 1500 ? "h5" : width > 1150 ? "h6" : "subtitle1"} sx={{marginTop: "20px", fontFamily: "Montserrat", fontWeight: "bold"}}>
                                        Book your appointment below now!
                                    </Typography>

                                    <div style={{marginTop: "30px", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: width > 1000 ? "" : "center"}}>
                                        <IconButton href="tel:(519) 821 - 6668" sx={{padding: "0", "&:hover": {backgroundColor: "transparent"}}}>
                                            <PhoneIcon sx={{color: "#fa6875", fontSize: "24px", "&:hover": {color: "#FE4252"}}} />
                                        </IconButton>
                                        <IconButton href="tel:(519) 821 - 6668" sx={{padding: "0", marginLeft: "20px", "&:hover": {backgroundColor: "transparent", textDecoration: "underline"}}}>
                                            <Typography align="center" variant={width > 1500 ? "h6" : width > 1150 ? "subtitle1" : "subtitle2"} sx={{fontFamily: "Montserrat", color: "black"}}>
                                                (519) - 821 - 6668
                                            </Typography>
                                        </IconButton>
                                    </div>

                                    <div style={{marginTop: "20px", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: width > 1000 ? "" : "center"}}>
                                        <IconButton href="mailto:guelphdental@hotmail.com" sx={{padding: "0", "&:hover": {backgroundColor: "transparent"}}}>
                                            <EmailIcon sx={{color: "#fa6875", fontSize: "24px", "&:hover": {color: "#FE4252"}}} />
                                        </IconButton>
                                        <IconButton href="mailto:guelphdental@hotmail.com" sx={{padding: "0", marginLeft: "20px", "&:hover": {backgroundColor: "transparent", textDecoration: "underline"}}}>
                                            <Typography align="center" variant={width > 1500 ? "h6" : width > 1150 ? "subtitle1" : "subtitle2"} sx={{fontFamily: "Montserrat", color: "black"}}>
                                                guelphdental@hotmail.com
                                            </Typography>
                                        </IconButton>
                                    </div>
                                </div>
                                <div style={{display: "flex", alignItems: "center", justifyContent: "center", marginLeft: width > 1600 ? "200px" : width > 1200 ? "160px" : width > 1100 ? "120px" : "60px"}}>
                                    <img src={image} alt="AppointmentImage" height="60%" width="100%" style={{borderRadius: "10px"}} />
                                </div>
                            </div>
                        </Grid>
                    :
                        <>
                            <Grid item xs={width > 1000 ? 6 : 12} sx={{display: "flex", justifyContent: "center", flexDirection: "column", marginTop: width > 1200 ? "30px" : width > 1000 ? "40px" : "130px"}}>
                                <Typography align={width > 1000 ? "left" : "center"} variant={width > 1500 ? "h4" : width > 1150 ? "h5" : "h6"} sx={{fontFamily: "Montserrat", fontWeight: "bold", marginLeft: width > 1700 ? "220px" : width > 1400 ? "180px" : width > 1000 ? "120px" : ""}}>
                                    Appointments
                                </Typography>

                                <Typography align={width > 1000 ? "left" : "center"} variant={width > 1500 ? "h5" : width > 1150 ? "h6" : "subtitle1"} sx={{fontFamily: "Montserrat", fontWeight: "bold", marginLeft: width > 1700 ? "220px" : width > 1400 ? "180px" : width > 1000 ? "120px" : "", marginTop: "20px"}}>
                                    Book your appointment below now!
                                </Typography>

                                <div style={{marginTop: "30px", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: width > 1000 ? "" : "center", marginLeft: width > 1700 ? "220px" : width > 1400 ? "180px" : width > 1000 ? "120px" : ""}}>
                                    <IconButton href="tel:(519) 821 - 6668" sx={{padding: "0", "&:hover": {backgroundColor: "transparent"}}}>
                                        <PhoneIcon sx={{color: "#fa6875", fontSize: "24px", "&:hover": {color: "#FE4252"}}} />
                                    </IconButton>
                                    <IconButton href="tel:(519) 821 - 6668" sx={{padding: "0", marginLeft: "20px", "&:hover": {backgroundColor: "transparent", textDecoration: "underline"}}}>
                                        <Typography align="center" variant={width > 1500 ? "h6" : width > 1150 ? "subtitle1" : "subtitle2"} sx={{fontFamily: "Montserrat", color: "black"}}>
                                            (519) - 821 - 6668
                                        </Typography>
                                    </IconButton>
                                </div>

                                <div style={{marginTop: "20px", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: width > 1000 ? "" : "center", marginLeft: width > 1700 ? "220px" : width > 1400 ? "180px" : width > 1000 ? "120px" : ""}}>
                                    <IconButton href="mailto:guelphdental@hotmail.com" sx={{padding: "0", "&:hover": {backgroundColor: "transparent"}}}>
                                        <EmailIcon sx={{color: "#fa6875", fontSize: "24px", "&:hover": {color: "#FE4252"}}} />
                                    </IconButton>
                                    <IconButton href="mailto:guelphdental@hotmail.com" sx={{padding: "0", marginLeft: "20px", "&:hover": {backgroundColor: "transparent", textDecoration: "underline"}}}>
                                        <Typography align="center" variant={width > 1500 ? "h6" : width > 1150 ? "subtitle1" : "subtitle2"} sx={{fontFamily: "Montserrat", color: "black"}}>
                                            guelphdental@hotmail.com
                                        </Typography>
                                    </IconButton>
                                </div>
                            </Grid>
                            <Grid item xs={width > 1000 ? 6 : 12} sx={{marginTop: width > 1000 ? "100px" : "50px", marginBottom: width > 1000 ? "50px" : "50px", display: width > 1000 ? "" : "flex", justifyContent: width > 1000 ? "" : "center"}}>
                                <img src={image} alt="AppointmentImage" height={width > 1000 ? "90%" : "auto"} width={width > 1000 ? "65%" : "60%"} style={{borderRadius: "10px", marginTop: width > 1600 ? "35px" : width > 1000 ? "10px" : "", marginLeft: width > 1000 ? "80px" : ""}} />
                            </Grid>
                        </>
                    }
                </Grid>
            :
            // Landing Banner Mobile
                <Grid container sx={{paddingLeft: "4%", paddingRight: "4%"}}>
                    <Grid item xs={12} sx={{display: "flex", justifyContent: "center", flexDirection: "column", marginTop: width > 600 ? "100px" : "90px", padding: width > 500 ? "4%" : "6%", backgroundColor: "#8985D3", borderRadius: "10px"}}>
                        <Typography align="center" variant="h6" sx={{fontFamily: "Montserrat", fontWeight: "bold", color: "white"}}>
                            Appointments
                        </Typography>

                        <Typography align="center" variant="subtitle1" sx={{fontFamily: "Montserrat", fontWeight: "bold", marginTop: "20px", color: "white"}}>
                            Book your appointment below now!
                        </Typography>

                        <div style={{marginTop: "30px", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center"}}>
                            <IconButton href="tel:(519) 821 - 6668" sx={{padding: "0", "&:hover": {backgroundColor: "transparent"}}}>
                                <PhoneIcon sx={{color: "#FFE347", fontSize: "30px"}} />
                            </IconButton>
                            <IconButton href="tel:(519) 821 - 6668" sx={{padding: "0", marginLeft: "20px", "&:hover": {backgroundColor: "transparent", textDecoration: "underline"}}}>
                                <Typography align="center" variant="subtitle2" sx={{fontFamily: "Montserrat", color: "white"}}>
                                    (519) - 821 - 6668
                                </Typography>
                            </IconButton>
                        </div>

                        <div style={{marginTop: "20px", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center"}}>
                            <IconButton href="mailto:guelphdental@hotmail.com" sx={{padding: "0", "&:hover": {backgroundColor: "transparent"}}}>
                                <EmailIcon sx={{color: "#FFE347", fontSize: "30px"}} />
                            </IconButton>
                            <IconButton href="mailto:guelphdental@hotmail.com" sx={{padding: "0", marginLeft: "20px", "&:hover": {backgroundColor: "transparent", textDecoration: "underline"}}}>
                                <Typography align="center" variant="subtitle2" sx={{fontFamily: "Montserrat", color: "white"}}>
                                    guelphdental@hotmail.com
                                </Typography>
                            </IconButton>
                        </div>
                    </Grid>
                </Grid>     
            }



            <Grid container sx={{marginTop: width > 600 ? "60px" : "30px", marginBottom: width > 1000 ? "60px" : width > 600 ? "60px" : "", display: "flex", justifyContent: "center", paddingLeft: width > 1700 ? "12%" : width > 600 ? "6%" : "", paddingRight: width > 1700 ? "12%" : width > 600 ? "6%" : ""}}>
                {/* Appointment Form Full */}
                {width > 600 ?
                    <Grid item xs={12}>
                        <Grid container sx={{padding: "2%", borderRadius: "10px"}}>
                            <Grid item xs={width > 1600 ? 5.75 : width > 1000 ? 5.5 : 12}>
                                <Typography variant={width > 1600 ? "h6" : "subtitle1"} sx={{fontFamily: "Montserrat", fontWeight: "bold"}}>
                                    Personal Information:
                                </Typography>

                                <Typography variant={width > 1600 ? "subtitle1" : "subtitle2"} sx={{fontFamily: "Montserrat"}}>
                                    <StarIcon sx={{marginRight: "5px", fontSize: "14px", color: "#fa6875"}} />
                                    indicate required fields
                                </Typography>

                                <div>
                                    <Typography variant={width > 1600 ? "h6" : "subtitle1"}  sx={{fontFamily: "Montserrat", fontWeight: "bold", marginTop: "30px", display: "flex", alignItems: "center"}}>
                                        First Name
                                        <StarIcon sx={{marginLeft: "5px", fontSize: "14px", color: "#fa6875"}} />
                                    </Typography>
                                    {firstNameError &&
                                        <div style={{display: "flex", flexDirection: "row", alignItems: "center", marginTop: "10px", marginBottom: "10px"}}>
                                            <NewReleasesIcon sx={{color: "#FB6D62"}} />
                                            <Typography variant={width > 1600 ? "subtitle1" : "subtitle2"} sx={{color: "#FB6D62", fontFamily: "Montserrat", marginLeft: "10px"}}>
                                                Please enter a first name
                                            </Typography>
                                        </div>
                                    }
                                </div>
                                <InputBase placeholder="First Name" onChange={handleFirstNameChange} value={firstName} sx={{backgroundColor: "#F9F9FA", marginTop: "10px", borderRadius: "10px", padding: "1% 2% 1% 2%", width: "80%", border: "1px solid #D4D6DC", input: {fontFamily: "Montserrat", marginLeft: "5px"}}} />

                                <div>
                                    <Typography variant={width > 1600 ? "h6" : "subtitle1"}  sx={{fontFamily: "Montserrat", fontWeight: "bold", marginTop: "30px", display: "flex", alignItems: "center"}}>
                                        Last Name
                                        <StarIcon sx={{marginLeft: "5px", fontSize: "14px", color: "#fa6875"}} />
                                    </Typography>
                                    {lastNameError &&
                                        <div style={{display: "flex", flexDirection: "row", alignItems: "center", marginTop: "10px", marginBottom: "10px"}}>
                                            <NewReleasesIcon sx={{color: "#FB6D62"}} />
                                            <Typography variant={width > 1600 ? "subtitle1" : "subtitle2"} sx={{color: "#FB6D62", fontFamily: "Montserrat", marginLeft: "10px"}}>
                                                Please enter a last name
                                            </Typography>
                                        </div>
                                    }
                                </div>
                                <InputBase placeholder="Last Name" onChange={handleLastNameChange} value={lastName} sx={{backgroundColor: "#F9F9FA", marginTop: "10px", borderRadius: "10px", padding: "1% 2% 1% 2%", width: "80%", border: "1px solid #D4D6DC", input: {fontFamily: "Montserrat", marginLeft: "5px"}}} />

                                <div>
                                    <Typography variant={width > 1600 ? "h6" : "subtitle1"}  sx={{fontFamily: "Montserrat", fontWeight: "bold", marginTop: "30px", display: "flex", alignItems: "center"}}>
                                        Email Address
                                        <StarIcon sx={{marginLeft: "5px", fontSize: "14px", color: "#fa6875"}} />
                                    </Typography>
                                    {emailError &&
                                        <div style={{display: "flex", flexDirection: "row", alignItems: "center", marginTop: "10px", marginBottom: "10px"}}>
                                            <NewReleasesIcon sx={{color: "#FB6D62"}} />
                                            <Typography variant={width > 1600 ? "subtitle1" : "subtitle2"} sx={{color: "#FB6D62", fontFamily: "Montserrat", marginLeft: "10px"}}>
                                                Please enter an email address
                                            </Typography>
                                        </div>
                                    }
                                </div>
                                <InputBase placeholder="Email Address" onChange={handleEmailAddressChange} value={emailAddress} sx={{backgroundColor: "#F9F9FA", marginTop: "10px", borderRadius: "10px", padding: "1% 2% 1% 2%", width: "80%", border: "1px solid #D4D6DC", input: {fontFamily: "Montserrat", marginLeft: "5px"}}} />

                                <div>
                                    <Typography variant={width > 1600 ? "h6" : "subtitle1"}  sx={{fontFamily: "Montserrat", fontWeight: "bold", marginTop: "30px", display: "flex", alignItems: "center"}}>
                                        Phone Number
                                        <StarIcon sx={{marginLeft: "5px", fontSize: "14px", color: "#fa6875"}} />
                                    </Typography>
                                    {phoneError &&
                                        <div style={{display: "flex", flexDirection: "row", alignItems: "center", marginTop: "10px", marginBottom: "10px"}}>
                                            <NewReleasesIcon sx={{color: "#FB6D62"}} />
                                            <Typography variant={width > 1600 ? "subtitle1" : "subtitle2"} sx={{color: "#FB6D62", fontFamily: "Montserrat", marginLeft: "10px"}}>
                                                Please enter a phone number
                                            </Typography>
                                        </div>
                                    }
                                </div>
                                <InputBase placeholder="Phone Number" onChange={handlePhoneNumberChange} value={phoneNumber} sx={{backgroundColor: "#F9F9FA", marginTop: "10px", borderRadius: "10px", padding: "1% 2% 1% 2%", width: "80%", border: "1px solid #D4D6DC", input: {fontFamily: "Montserrat", marginLeft: "5px"}}} />

                                <div>
                                    <Typography variant={width > 1600 ? "h6" : "subtitle1"} sx={{fontFamily: "Montserrat", fontWeight: "bold", marginTop: "30px", display: "flex", alignItems: "center"}}>
                                        Existing Patient?
                                        <StarIcon sx={{marginLeft: "5px", fontSize: "14px", color: "#fa6875"}} />
                                    </Typography>
                                    {existingPatientError &&
                                        <div style={{display: "flex", flexDirection: "row", alignItems: "center", marginTop: "10px", marginBottom: "10px"}}>
                                            <NewReleasesIcon sx={{color: "#FB6D62"}} />
                                            <Typography variant={width > 1600 ? "subtitle1" : "subtitle2"} sx={{color: "#FB6D62", fontFamily: "Montserrat", marginLeft: "10px"}}>
                                                Please choose if you are an existing or new patient
                                            </Typography>
                                        </div>
                                    }
                                </div>
                                <Button onClick={existPatientHandler} sx={{borderRadius: "15px", border: "1px solid #7DAEFF", marginTop: "10px", padding: "2%", textTransform: "none", backgroundColor: existPatient === true ? "#CBDBFF" : "", "&:hover": {backgroundColor: existPatient === true ? "#B9CEFF" : "#F0F6FF"}}}>
                                    <Radio onChange={existPatientHandler} checked={existPatient} sx={{padding: "0", color: "#7daeff"}} />
                                    <Typography sx={{marginLeft: "10px", fontFamily: "Montserrat", fontWeight: "bold", fontSize: width <= 1600 && "14px"}}>Existing Patient</Typography>
                                </Button>
                                <Button onClick={newPatientHandler} sx={{borderRadius: "15px", border: "1px solid #7DAEFF", marginTop: "10px", padding: "2%", textTransform: "none", marginLeft: "20px", backgroundColor: newPatient === true ? "#CBDBFF" : "", "&:hover": {backgroundColor: newPatient === true ? "#B9CEFF" : "#F0F6FF"}}}>
                                    <Radio onChange={newPatientHandler} checked={newPatient} sx={{padding: "0", color: "#7daeff"}} />
                                    <Typography sx={{marginLeft: "10px", fontFamily: "Montserrat", fontWeight: "bold", fontSize: width <= 1600 && "14px"}}>New Patient</Typography>
                                </Button>

                                {width > 1000 &&
                                    <>
                                        <Typography variant={width > 1600 ? "h6" : "subtitle1"}  sx={{fontFamily: "Montserrat", fontWeight: "bold", marginTop: "50px"}}>
                                            Please note: All appointment changes and cancellations must be made by phone and will not be accepted through this form
                                        </Typography>
                                        <Button onClick={handleSubmit} variant="standard" sx={{borderRadius: "15px", backgroundColor: "#fa7167", fontFamily: "Montserrat", fontSize: width > 1600 ? "16px" : "14px", fontWeight: "bold", color: "white", marginTop: "20px", padding: "2%", "&:hover": {backgroundColor: "#FF6155"}}}>
                                            Submit
                                        </Button>
                                        <Button onClick={handleClear} variant="standard" sx={{borderRadius: "15px", marginLeft: "20px", fontFamily: "Montserrat", fontSize: width > 1600 ? "16px" : "14px", fontWeight: "bold", color: "#fa7167", marginTop: "20px", border: "1px solid #fa7167", padding: "2%", "&:hover": {backgroundColor: "#FAECEB"}}}>
                                            Clear
                                        </Button>
                                        {appointmentRequested &&
                                            <div style={{marginTop: "50px", display: "flex", flexDirection: "row", alignItems: "center"}}>
                                                <CheckIcon sx={{color: "#31DD6B", fontSize: "30px"}} />
                                                <Typography variant={width > 1600 ? "h6" : "subtitle1"}  sx={{marginLeft: "20px", fontFamily: "Montserrat", fontWeight: "bold", color: "#31DD6B"}}>
                                                    Your appointment request has been submitted, we will get back to you as soon as possible!
                                                </Typography>
                                            </div>
                                        }
                                    </>
                                }
                            </Grid>
                            <Grid item xs={width > 1600 ? 0.5 : 1} />
                            <Grid item xs={width > 1600 ? 5.75 : width > 1000 ? 5.5 : 12} sx={{marginTop: width > 1000 ? "" : "70px"}}>
                                <Typography variant={width > 1600 ? "h6" : "subtitle1"}  sx={{fontFamily: "Montserrat", fontWeight: "bold"}}>
                                    Availability:
                                </Typography>
                                <Typography variant={width > 1600 ? "subtitle1" : "subtitle2"}  sx={{fontFamily: "Montserrat"}}>
                                    All fields below are optional
                                </Typography>

                                <Typography variant={width > 1600 ? "h6" : "subtitle1"}  sx={{fontFamily: "Montserrat", fontWeight: "bold", marginTop: "30px"}}>
                                    Select the days you are available
                                </Typography>
                                <div style={{marginTop: "10px"}}>
                                    <Button onClick={mondayHandler} variant="standard" sx={{borderRadius: "15px", fontSize: width > 1600 ? "" : "12px", color: "#1976d2", border: "1px solid #7daeff", backgroundColor: monday && "#C9D7F8", fontFamily: "Montserrat", fontWeight: "bold", padding: "2%", transition: "0.3s", "&:hover": {backgroundColor: monday ? "#B7CAF5" : ""}}}>Monday</Button>
                                    <Button onClick={tuesdayHandler} variant="standard" sx={{borderRadius: "15px", fontSize: width > 1600 ? "" : "12px", color: "#1976d2", border: "1px solid #7daeff", backgroundColor: tuesday && "#C9D7F8", marginLeft: "20px", fontFamily: "Montserrat", fontWeight: "bold", padding: "2%", transition: "0.3s", "&:hover": {backgroundColor: tuesday ? "#B7CAF5" : ""}}}>Tuesday</Button>
                                    <Button onClick={wednesdayHandler} variant="standard" sx={{borderRadius: "15px", fontSize: width > 1600 ? "" : "12px", color: "#1976d2", border: "1px solid #7daeff", backgroundColor: wednesday && "#C9D7F8", marginLeft: "20px", fontFamily: "Montserrat", fontWeight: "bold", padding: "2%", transition: "0.3s", "&:hover": {backgroundColor: wednesday ? "#B7CAF5" : ""}}}>Wednesday</Button>
                                    {width > 1500 &&
                                        <Button onClick={thursdayHandler} variant="standard" sx={{borderRadius: "15px", fontSize: width > 1600 ? "" : "12px", color: "#1976d2", border: "1px solid #7daeff", backgroundColor: thursday && "#C9D7F8", marginLeft: "20px", fontFamily: "Montserrat", fontWeight: "bold", padding: "2%", transition: "0.3s", "&:hover": {backgroundColor: thursday ? "#B7CAF5" : ""}}}>Thursday</Button>
                                    }
                                </div>

                                {width > 1500 &&
                                    <div style={{marginTop: "20px"}}>
                                        <Button onClick={fridayHandler} variant="standard" sx={{borderRadius: "15px", fontSize: width > 1600 ? "" : "12px", color: "#1976d2", border: "1px solid #7daeff", backgroundColor: friday && "#C9D7F8", fontFamily: "Montserrat", fontWeight: "bold", padding: "2%", transition: "0.3s", "&:hover": {backgroundColor: friday ? "#B7CAF5" : ""}}}>Friday</Button>
                                        <Button onClick={saturdayHandler} variant="standard" sx={{borderRadius: "15px", fontSize: width > 1600 ? "" : "12px", color: "#1976d2", border: "1px solid #7daeff", backgroundColor: saturday && "#C9D7F8", marginLeft: "20px", fontFamily: "Montserrat", fontWeight: "bold", padding: "2%", transition: "0.3s", "&:hover": {backgroundColor: saturday ? "#B7CAF5" : ""}}}>Saturday</Button>
                                    </div>
                                }


                                {width <= 1500 &&
                                    <div style={{marginTop: "20px"}}>
                                        <Button onClick={thursdayHandler} variant="standard" sx={{borderRadius: "15px", fontSize: width > 1600 ? "" : "12px", color: "#1976d2", border: "1px solid #7daeff", backgroundColor: thursday && "#C9D7F8", fontFamily: "Montserrat", fontWeight: "bold", padding: "2%", transition: "0.3s", "&:hover": {backgroundColor: thursday ? "#B7CAF5" : ""}}}>Thursday</Button>
                                        <Button onClick={fridayHandler} variant="standard" sx={{borderRadius: "15px", fontSize: width > 1600 ? "" : "12px", color: "#1976d2", border: "1px solid #7daeff", backgroundColor: friday && "#C9D7F8", marginLeft: "20px", fontFamily: "Montserrat", fontWeight: "bold", padding: "2%", transition: "0.3s", "&:hover": {backgroundColor: friday ? "#B7CAF5" : ""}}}>Friday</Button>
                                        <Button onClick={saturdayHandler} variant="standard" sx={{borderRadius: "15px", fontSize: width > 1600 ? "" : "12px", color: "#1976d2", border: "1px solid #7daeff", backgroundColor: saturday && "#C9D7F8", marginLeft: "20px", fontFamily: "Montserrat", fontWeight: "bold", padding: "2%", transition: "0.3s", "&:hover": {backgroundColor: saturday ? "#B7CAF5" : ""}}}>Saturday</Button>
                                    </div>
                                }

                                <Typography variant={width > 1600 ? "h6" : "subtitle1"}  sx={{fontFamily: "Montserrat", fontWeight: "bold", marginTop: "30px"}}>
                                    Best time for appointment
                                </Typography>
                                <Typography variant={width > 1600 ? "subtitle1" : "subtitle2"}  sx={{fontFamily: "Montserrat"}}>
                                    Appointments are generally 1 hour
                                </Typography>
                                <Button onClick={openTime} sx={{borderRadius: "15px", border: "1px solid #7DAEFF", marginTop: "10px", padding: "2%", textTransform: "none"}}>
                                    <Typography sx={{fontFamily: "Montserrat", fontWeight: "bold", marginLeft: "5px", fontSize: width <= 1600 && "14px"}}>
                                        {chosenTime !== "" ? chosenTime : "Time"}
                                    </Typography>
                                    <ArrowDropDownIcon sx={{marginLeft: "10px"}} />
                                </Button>
                                <Menu anchorEl={timeMenu} open={openTimeMenu} onClose={() => {setTimeMenu(null);}} disableScrollLock={true}>
                                    {timeArray.map((hour, ID) => (
                                        <MenuItem onClick={() => {timeHandler(hour)}} key={ID} sx={{fontFamily: "Montserrat", backgroundColor: chosenTime === hour ? "#E1E5ED" : ""}}>
                                            {hour}
                                        </MenuItem>
                                    ))}
                                </Menu>

                                <Typography variant={width > 1600 ? "h6" : "subtitle1"}  sx={{fontFamily: "Montserrat", fontWeight: "bold", marginTop: "30px"}}>
                                    Best method to contact you
                                </Typography>
                                <Button onClick={emailHandler} sx={{borderRadius: "15px", border: "1px solid #7DAEFF", marginTop: "10px", padding: "2%", textTransform: "none", backgroundColor: email === true ? "#CBDBFF" : "", "&:hover": {backgroundColor: email === true ? "#B9CEFF" : "#F0F6FF"}}}>
                                    <EmailIcon />
                                    <Typography sx={{marginLeft: "10px", fontFamily: "Montserrat", fontWeight: "bold", fontSize: width <= 1600 && "14px"}}>Email</Typography>
                                </Button>
                                <Button onClick={phoneHandler} sx={{borderRadius: "15px", border: "1px solid #7DAEFF", marginTop: "10px", marginLeft: "20px", padding: "2%", textTransform: "none", backgroundColor: phone === true ? "#CBDBFF" : "", "&:hover": {backgroundColor: phone === true ? "#B9CEFF" : "#F0F6FF"}}}>
                                    <PhoneIcon />
                                    <Typography sx={{marginLeft: "10px", fontFamily: "Montserrat", fontWeight: "bold", fontSize: width <= 1600 && "14px"}}>Phone</Typography>
                                </Button>

                                <Typography variant={width > 1600 ? "h6" : "subtitle1"}  sx={{fontFamily: "Montserrat", fontWeight: "bold", marginTop: "30px"}}>
                                    Best time to contact you
                                </Typography>
                                <Button onClick={morningHandler} sx={{borderRadius: "15px", border: "1px solid #7DAEFF", marginTop: "10px", padding: "2%", textTransform: "none", backgroundColor: morning === true ? "#CBDBFF" : "", "&:hover": {backgroundColor: morning === true ? "#B9CEFF" : "#F0F6FF"}}}>
                                    <Radio onChange={morningHandler} checked={morning} sx={{padding: "0", color: "#7daeff"}} />
                                    <Typography sx={{marginLeft: "10px", fontFamily: "Montserrat", fontWeight: "bold", fontSize: width <= 1600 && "14px"}}>Morning</Typography>
                                </Button>
                                <Button onClick={afternoonHandler} sx={{borderRadius: "15px", border: "1px solid #7DAEFF", marginTop: "10px", marginLeft: "20px", padding: "2%", textTransform: "none", backgroundColor: afternoon === true ? "#CBDBFF" : "", "&:hover": {backgroundColor: afternoon === true ? "#B9CEFF" : "#F0F6FF"}}}>
                                    <Radio onChange={afternoonHandler} checked={afternoon} sx={{padding: "0", color: "#7daeff"}} />
                                    <Typography sx={{marginLeft: "10px", fontFamily: "Montserrat", fontWeight: "bold", fontSize: width <= 1600 && "14px"}}>Afternoon</Typography>
                                </Button>

                                <Typography variant={width > 1600 ? "h6" : "subtitle1"}  sx={{fontFamily: "Montserrat", fontWeight: "bold", marginTop: "30px"}}>
                                    Reason for Appointment
                                </Typography>
                                <Button onClick={openReason} sx={{borderRadius: "15px", border: "1px solid #7DAEFF", marginTop: "10px", padding: "2%", textTransform: "none"}}>
                                    <Typography sx={{fontFamily: "Montserrat", fontWeight: "bold", marginLeft: "5px", fontSize: width <= 1600 && "14px"}}>
                                        {chosenReason !== "" ? chosenReason : "Reason"}
                                    </Typography>
                                    <ArrowDropDownIcon sx={{marginLeft: "10px"}} />
                                </Button>
                                <Menu anchorEl={reasonMenu} open={openReasonMenu} onClose={() => {setReasonMenu(null);}} disableScrollLock={true}>
                                    {reasonArray.map((reason, ID) => (
                                        <MenuItem onClick={() => {reasonHandler(reason)}} key={ID} sx={{fontFamily: "Montserrat", backgroundColor: chosenReason === reason ? "#E1E5ED" : ""}}>
                                            {reason}
                                        </MenuItem>
                                    ))}
                                </Menu>

                                {width <= 1000 &&
                                    <>
                                        <Typography variant={width > 1600 ? "h6" : "subtitle1"}  sx={{fontFamily: "Montserrat", fontWeight: "bold", marginTop: "50px"}}>
                                            Please note: All appointment changes and cancellations must be made by phone and will not be accepted through this form
                                        </Typography>
                                        <Button onClick={handleSubmit} variant="standard" sx={{borderRadius: "15px", backgroundColor: "#fa7167", fontFamily: "Montserrat", fontSize: "16px", fontWeight: "bold", color: "white", marginTop: "20px", padding: "1% 2% 1% 2%", "&:hover": {backgroundColor: "#FF6155"}}}>
                                            Submit
                                        </Button>
                                        <Button onClick={handleClear} variant="standard" sx={{borderRadius: "15px", marginLeft: "20px", fontFamily: "Montserrat", fontSize: "16px", fontWeight: "bold", color: "#fa7167", marginTop: "20px", border: "1px solid #fa7167", padding: "1% 2% 1% 2%"}}>
                                            Clear
                                        </Button>

                                        {appointmentRequested &&
                                            <div style={{marginTop: "40px", display: "flex", flexDirection: "row", alignItems: "center"}}>
                                                <CheckIcon sx={{color: "#31DD6B", fontSize: "30px"}} />
                                                <Typography variant={width > 1600 ? "h6" : "subtitle1"}  sx={{marginLeft: "20px", fontFamily: "Montserrat", fontWeight: "bold", color: "#31DD6B"}}>
                                                    Your appointment request has been submitted, we will get back to you as soon as possible!
                                                </Typography>
                                            </div>
                                        }
                                    </>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                :
                // Appointment Form Mobile
                    <Grid container sx={{paddingLeft: "4%", paddingRight: "4%", marginBottom: "30px"}}>
                        <Grid item xs={12} sx={{padding: width > 500 ? "4%" : "6%", backgroundColor: "#6897FF", borderRadius: "10px"}}>
                            <Typography variant="subtitle1" sx={{fontFamily: "Montserrat", fontWeight: "bold", color: "white"}}>
                                Personal Information
                            </Typography>

                            <Typography variant="subtitle2"  sx={{fontFamily: "Montserrat", color: "white"}}>
                                All fields below are mandatory
                            </Typography>

                            <div>
                                <Typography variant="subtitle1"  sx={{fontFamily: "Montserrat", fontWeight: "bold", marginTop: "30px", color: "white", display: "flex", alignItems: "center"}}>
                                    First Name
                                    <StarIcon sx={{marginLeft: "5px", fontSize: "14px", color: "#fa6875"}} />
                                </Typography>
                                {firstNameError &&
                                    <div style={{display: "flex", flexDirection: "row", alignItems: "center", marginTop: "10px", marginBottom: "10px"}}>
                                        <NewReleasesIcon sx={{color: "#FCB48C"}} />
                                        <Typography variant="subtitle2" sx={{color: "#FCB48C", fontFamily: "Montserrat", marginLeft: "10px"}}>
                                            Please enter a first name
                                        </Typography>
                                    </div>
                                }                      
                            </div>
                            <InputBase placeholder="First Name" onChange={handleFirstNameChange} value={firstName} sx={{backgroundColor: "#e5f9fb", marginTop: "10px", borderRadius: "10px", padding: "2% 2% 2% 2%", width: "100%", input: {fontFamily: "Montserrat", marginLeft: "5px"}}} />

                            <div>
                                <Typography variant="subtitle1"  sx={{fontFamily: "Montserrat", fontWeight: "bold", marginTop: "30px", color: "white", display: "flex", alignItems: "center"}}>
                                    Last Name
                                    <StarIcon sx={{marginLeft: "5px", fontSize: "14px", color: "#fa6875"}} />
                                </Typography>
                                {lastNameError &&
                                    <div style={{display: "flex", flexDirection: "row", alignItems: "center", marginTop: "10px", marginBottom: "10px"}}>
                                        <NewReleasesIcon sx={{color: "#FCB48C"}} />
                                        <Typography variant="subtitle2" sx={{color: "#FCB48C", fontFamily: "Montserrat", marginLeft: "10px"}}>
                                            Please enter a last name
                                        </Typography>
                                    </div>
                                }     
                            </div>
                            <InputBase placeholder="Last Name" onChange={handleLastNameChange} value={lastName} sx={{backgroundColor: "#e5f9fb", marginTop: "10px", borderRadius: "10px", padding: "2% 2% 2% 2%", width: "100%", input: {fontFamily: "Montserrat", marginLeft: "5px"}}} />

                            <div>
                                <Typography variant="subtitle1"  sx={{fontFamily: "Montserrat", fontWeight: "bold", marginTop: "30px", color: "white", display: "flex", alignItems: "center"}}>
                                    Email Address
                                    <StarIcon sx={{marginLeft: "5px", fontSize: "14px", color: "#fa6875"}} />
                                </Typography>
                                {emailError &&
                                    <div style={{display: "flex", flexDirection: "row", alignItems: "center", marginTop: "10px", marginBottom: "10px"}}>
                                        <NewReleasesIcon sx={{color: "#FCB48C"}} />
                                        <Typography variant="subtitle2" sx={{color: "#FCB48C", fontFamily: "Montserrat", marginLeft: "10px"}}>
                                            Please enter a email address
                                        </Typography>
                                    </div>
                                }     
                            </div>
                            <InputBase placeholder="Email Address" onChange={handleEmailAddressChange} value={emailAddress} sx={{backgroundColor: "#e5f9fb", marginTop: "10px", borderRadius: "10px", padding: "2% 2% 2% 2%", width: "100%", input: {fontFamily: "Montserrat", marginLeft: "5px"}}} />

                            <div>
                                <Typography variant="subtitle1"  sx={{fontFamily: "Montserrat", fontWeight: "bold", marginTop: "30px", color: "white", display: "flex", alignItems: "center"}}>
                                    Phone Number
                                    <StarIcon sx={{marginLeft: "5px", fontSize: "14px", color: "#fa6875"}} />
                                </Typography>
                                {phoneError &&
                                    <div style={{display: "flex", flexDirection: "row", alignItems: "center", marginTop: "10px", marginBottom: "10px"}}>
                                        <NewReleasesIcon sx={{color: "#FCB48C"}} />
                                        <Typography variant="subtitle2" sx={{color: "#FCB48C", fontFamily: "Montserrat", marginLeft: "10px"}}>
                                            Please enter a phone number
                                        </Typography>
                                    </div>
                                }     
                            </div>
                            <InputBase placeholder="Phone Number" onChange={handlePhoneNumberChange} value={phoneNumber} sx={{backgroundColor: "#e5f9fb", marginTop: "10px", borderRadius: "10px", padding: "2% 2% 2% 2%", width: "100%", input: {fontFamily: "Montserrat", marginLeft: "5px"}}} />

                            <div>
                                <Typography variant="subtitle1" sx={{fontFamily: "Montserrat", fontWeight: "bold", marginTop: "30px", color: "white", display: "flex", alignItems: "center"}}>
                                    Existing Patient?
                                    <StarIcon sx={{marginLeft: "5px", fontSize: "14px", color: "#fa6875"}} />
                                </Typography>
                                {existingPatientError &&
                                    <div style={{display: "flex", flexDirection: "row", alignItems: "center", marginTop: "10px", marginBottom: "10px"}}>
                                        <NewReleasesIcon sx={{color: "#FCB48C"}} />
                                        <Typography variant="subtitle2" sx={{color: "#FCB48C", fontFamily: "Montserrat", marginLeft: "10px"}}>
                                            Please choose if you are an existing or new patient
                                        </Typography>
                                    </div>
                                }     
                            </div>

                            <div>
                                <Button onClick={existPatientHandler} sx={{transition: "0.6s", borderRadius: "15px", marginTop: "10px", padding: "3%", textTransform: "none", backgroundColor: existPatient === true ? "#7B6EEF" : "#e5f9fb", "&:hover": {backgroundColor: existPatient === true ? "#7B6EEF" : "#F0F6FF"}}}>
                                    <Radio onChange={existPatientHandler} checked={existPatient} sx={{padding: "0", color: "#5897FF", "&, &.Mui-checked": {transition: "0.5s", color: existPatient ? "white" : "#5897FF"}}} />
                                    <Typography sx={{transition: "0.5s", marginLeft: "10px", fontFamily: "Montserrat", fontWeight: "bold", fontSize: "14px", color: existPatient ? "white" : "#5897FF"}}>Existing Patient</Typography>
                                </Button>
                                {width > 500 &&
                                    <Button onClick={newPatientHandler} sx={{transition: "0.6s", borderRadius: "15px", marginTop: "10px", padding: "3%", textTransform: "none", marginLeft: "20px", backgroundColor: newPatient === true ? "#7B6EEF" : "#e5f9fb", "&:hover": {backgroundColor: newPatient === true ? "#7B6EEF" : "#F0F6FF"}}}>
                                        <Radio onChange={newPatientHandler} checked={newPatient} sx={{padding: "0", color: "#5897FF", "&, &.Mui-checked": {transition: "0.5s", color: newPatient ? "white" : "#5897FF"}}} />
                                        <Typography sx={{transition: "0.5s", marginLeft: "10px", fontFamily: "Montserrat", fontWeight: "bold", fontSize: "14px", color: newPatient ? "white" : "#5897FF"}}>New Patient</Typography>
                                    </Button>
                                }
                            </div>
                            {width <= 500 &&
                                <Button onClick={newPatientHandler} sx={{transition: "0.6s", borderRadius: "15px", marginTop: "20px", padding: "3%", textTransform: "none", backgroundColor: newPatient === true ? "#7B6EEF" : "#e5f9fb", "&:hover": {backgroundColor: newPatient === true ? "#7B6EEF" : "#F0F6FF"}}}>
                                    <Radio onChange={newPatientHandler} checked={newPatient} sx={{padding: "0", color: "#5897FF", "&, &.Mui-checked": {transition: "0.5s", color: newPatient ? "white" : "#5897FF"}}} />
                                    <Typography sx={{transition: "0.5s", marginLeft: "10px", fontFamily: "Montserrat", fontWeight: "bold", fontSize: "14px", color: newPatient ? "white" : "#5897FF"}}>New Patient</Typography>
                                </Button>
                            }
                        </Grid>

                        <Grid item xs={12} sx={{padding: width > 500 ? "4%" : "6%", backgroundColor: "#7B6EEF", borderRadius: "10px", marginTop: "30px"}}>
                            <Typography variant="subtitle1"  sx={{fontFamily: "Montserrat", fontWeight: "bold", color: "white"}}>
                                Availability
                            </Typography>
                            <Typography variant="subtitle2"  sx={{fontFamily: "Montserrat", color: "white"}}>
                                All fields below are optional
                            </Typography>

                            <Typography variant="subtitle1"  sx={{fontFamily: "Montserrat", fontWeight: "bold", marginTop: "30px", color: "white"}}>
                                Select the days you are available
                            </Typography>
                            <div style={{marginTop: "10px"}}>
                                <Button onClick={mondayHandler} variant="standard" sx={{borderRadius: "15px", fontSize: "12px", color: monday ? "black" : "#FCE762", border: "1px solid #FCE762", backgroundColor: monday && "#e5f9fb", fontFamily: "Montserrat", fontWeight: "bold", padding: "3%", transition: "0.3s", "&:hover": {backgroundColor: monday ? "#e5f9fb" : ""}}}>Monday</Button>
                                <Button onClick={tuesdayHandler} variant="standard" sx={{borderRadius: "15px", fontSize: "12px", color: tuesday ? "black" : "#FCE762", border: "1px solid #FCE762", backgroundColor: tuesday && "#e5f9fb", marginLeft: "20px", fontFamily: "Montserrat", fontWeight: "bold", padding: "3%", transition: "0.3s", "&:hover": {backgroundColor: tuesday ? "#e5f9fb" : ""}}}>Tuesday</Button>
                                {width > 500 &&
                                    <Button onClick={wednesdayHandler} variant="standard" sx={{borderRadius: "15px", fontSize: "12px", color: wednesday ? "black" : "#FCE762", border: "1px solid #FCE762", backgroundColor: wednesday && "#e5f9fb", marginLeft: "20px", fontFamily: "Montserrat", fontWeight: "bold", padding: "3%", transition: "0.3s", "&:hover": {backgroundColor: wednesday ? "#e5f9fb" : ""}}}>Wednesday</Button>
                                }
                            </div>

                            <div style={{marginTop: "20px"}}>
                                {width <= 500 &&
                                    <Button onClick={wednesdayHandler} variant="standard" sx={{borderRadius: "15px", fontSize: "12px", color: wednesday ? "black" : "#FCE762", border: "1px solid #FCE762", backgroundColor: wednesday && "#e5f9fb", fontFamily: "Montserrat", fontWeight: "bold", padding: "3%", transition: "0.3s", "&:hover": {backgroundColor: wednesday ? "#e5f9fb" : ""}}}>Wednesday</Button>
                                }
                                <Button onClick={thursdayHandler} variant="standard" sx={{borderRadius: "15px", fontSize: "12px", color: thursday ? "black" : "#FCE762", border: "1px solid #FCE762", backgroundColor: thursday && "#e5f9fb", marginLeft: width > 500 ? "" : "20px", fontFamily: "Montserrat", fontWeight: "bold", padding: "3%", transition: "0.3s", "&:hover": {backgroundColor: thursday ? "#e5f9fb" : ""}}}>Thursday</Button>
                                {width > 500 &&
                                    <>
                                        <Button onClick={fridayHandler} variant="standard" sx={{borderRadius: "15px", fontSize: "12px", color: friday ? "black" : "#FCE762", border: "1px solid #FCE762", backgroundColor: friday && "#e5f9fb", marginLeft: "20px", fontFamily: "Montserrat", fontWeight: "bold", padding: "3%", transition: "0.3s", "&:hover": {backgroundColor: friday ? "#e5f9fb" : ""}}}>Friday</Button>
                                        <Button onClick={saturdayHandler} variant="standard" sx={{borderRadius: "15px", fontSize: "12px", color: saturday ? "black" : "#FCE762", border: "1px solid #FCE762", backgroundColor: saturday && "#e5f9fb", marginLeft: "20px", fontFamily: "Montserrat", fontWeight: "bold", padding: "3%", transition: "0.3s", "&:hover": {backgroundColor: saturday ? "#e5f9fb" : ""}}}>Saturday</Button>
                                    </>
                                }
                            </div>

                            {width <= 500 &&
                                <div style={{marginTop: "20px"}}>
                                    <Button onClick={fridayHandler} variant="standard" sx={{borderRadius: "15px", fontSize: "12px", color: friday ? "black" : "#FCE762", border: "1px solid #FCE762", backgroundColor: friday && "#e5f9fb", fontFamily: "Montserrat", fontWeight: "bold", padding: "3%", transition: "0.3s", "&:hover": {backgroundColor: friday ? "#e5f9fb" : ""}}}>Friday</Button>
                                    <Button onClick={saturdayHandler} variant="standard" sx={{borderRadius: "15px", fontSize: "12px", color: saturday ? "black" : "#FCE762", border: "1px solid #FCE762", backgroundColor: saturday && "#e5f9fb", marginLeft: "20px", fontFamily: "Montserrat", fontWeight: "bold", padding: "3%", transition: "0.3s", "&:hover": {backgroundColor: saturday ? "#e5f9fb" : ""}}}>Saturday</Button>
                                </div>
                            }

                            <Typography variant="subtitle1"  sx={{fontFamily: "Montserrat", fontWeight: "bold", marginTop: "30px", color: "white"}}>
                                Best time for appointment
                            </Typography>
                            <Typography variant="subtitle2"  sx={{fontFamily: "Montserrat", color: "white"}}>
                                Appointments are generally 1 hour
                            </Typography>
                            <Button onClick={openTime} sx={{borderRadius: "15px", border: "1px solid #FCE762", marginTop: "10px", padding: "3%", textTransform: "none"}}>
                                <Typography sx={{fontFamily: "Montserrat", fontWeight: "bold", marginLeft: "5px", fontSize: "14px", color: "#FCE762"}}>
                                    {chosenTime !== "" ? chosenTime : "Time"}
                                </Typography>
                                <ArrowDropDownIcon sx={{marginLeft: "10px", color: "#FCE762"}} />
                            </Button>
                            <Menu anchorEl={timeMenu} open={openTimeMenu} onClose={() => {setTimeMenu(null);}} disableScrollLock={true}>
                                {timeArray.map((hour, ID) => (
                                    <MenuItem onClick={() => {timeHandler(hour)}} key={ID} sx={{fontFamily: "Montserrat", backgroundColor: chosenTime === hour ? "#E1E5ED" : ""}}>
                                        {hour}
                                    </MenuItem>
                                ))}
                            </Menu>

                            <Typography variant="subtitle1" sx={{fontFamily: "Montserrat", fontWeight: "bold", marginTop: "30px", color: "white"}}>
                                Best method to contact you
                            </Typography>
                            <Button onClick={emailHandler} sx={{transition: "0.5s", borderRadius: "15px", border: "1px solid #FCE762", marginTop: "10px", padding: "3%", textTransform: "none", backgroundColor: email === true ? "#e5f9fb" : "", "&:hover": {backgroundColor: email === true ? "#e5f9fb" : "#F0F6FF"}}}>
                                <EmailIcon sx={{transition: "0.5s", color: email ? "black" : "#FCE762"}} />
                                <Typography sx={{transition: "0.5s", marginLeft: "10px", fontFamily: "Montserrat", fontWeight: "bold", fontSize: "14px", color: email ? "black" : "#FCE762"}}>Email</Typography>
                            </Button>
                            <Button onClick={phoneHandler} sx={{transition: "0.5s", borderRadius: "15px", border: "1px solid #FCE762", marginTop: "10px", marginLeft: "20px", padding: "3%", textTransform: "none", backgroundColor: phone === true ? "#e5f9fb" : "", "&:hover": {backgroundColor: phone === true ? "#e5f9fb" : "#F0F6FF"}}}>
                                <PhoneIcon sx={{transition: "0.5s", color: phone ? "black" : "#FCE762"}} />
                                <Typography sx={{transition: "0.5s", marginLeft: "10px", fontFamily: "Montserrat", fontWeight: "bold", fontSize: "14px", color: phone ? "black" : "#FCE762"}}>Phone</Typography>
                            </Button>

                            <Typography variant="subtitle1" sx={{fontFamily: "Montserrat", fontWeight: "bold", marginTop: "30px", color: "white"}}>
                                Best time to contact you
                            </Typography>
                            <Button onClick={morningHandler} sx={{transition: "0.5s", borderRadius: "15px", border: "1px solid #FCE762", marginTop: "10px", padding: "3%", textTransform: "none", backgroundColor: morning === true ? "#e5f9fb" : "", "&:hover": {backgroundColor: morning === true ? "#e5f9fb" : "#F0F6FF"}}}>
                                <Radio onChange={morningHandler} checked={morning} sx={{padding: "0", color: morning ? "black" : "#FCE762", "&, &.Mui-checked": {transition: "0.5s", color: morning ? "black" : "#FCE762"}}} />
                                <Typography sx={{transition: "0.5s", marginLeft: "10px", fontFamily: "Montserrat", fontWeight: "bold", fontSize: "14px", color: morning ? "black" : "#FCE762"}}>Morning</Typography>
                            </Button>
                            <Button onClick={afternoonHandler} sx={{transition: "0.5s", borderRadius: "15px", border: "1px solid #FCE762", marginTop: "10px", marginLeft: "20px", padding: "3%", textTransform: "none", backgroundColor: afternoon === true ? "#e5f9fb" : "", "&:hover": {backgroundColor: afternoon === true ? "#e5f9fb" : "#F0F6FF"}}}>
                                <Radio onChange={afternoonHandler} checked={afternoon} sx={{padding: "0", color: afternoon ? "black" : "#FCE762", "&, &.Mui-checked": {transition: "0.5s", color: afternoon ? "black" : "#FCE762"}}} />
                                <Typography sx={{transition: "0.5s", marginLeft: "10px", fontFamily: "Montserrat", fontWeight: "bold", fontSize: "14px", color: afternoon ? "black" : "#FCE762"}}>Afternoon</Typography>
                            </Button>

                            <Typography variant="subtitle1"  sx={{fontFamily: "Montserrat", fontWeight: "bold", marginTop: "30px", color: "white"}}>
                                Reason for Appointment
                            </Typography>
                            <Button onClick={openReason} sx={{borderRadius: "15px", border: "1px solid #FCE762", marginTop: "10px", padding: "3%", textTransform: "none"}}>
                                <Typography sx={{fontFamily: "Montserrat", fontWeight: "bold", marginLeft: "5px", fontSize: "14px", color: "#FCE762"}}>
                                    {chosenReason !== "" ? chosenReason : "Reason"}
                                </Typography>
                                <ArrowDropDownIcon sx={{marginLeft: "10px", color: "#FCE762"}} />
                            </Button>
                            <Menu anchorEl={reasonMenu} open={openReasonMenu} onClose={() => {setReasonMenu(null);}} disableScrollLock={true}>
                                {reasonArray.map((reason, ID) => (
                                    <MenuItem onClick={() => {reasonHandler(reason)}} key={ID} sx={{fontFamily: "Montserrat", backgroundColor: chosenReason === reason ? "#E1E5ED" : ""}}>
                                        {reason}
                                    </MenuItem>
                                ))}
                            </Menu>
                        </Grid>

                        <Grid item xs={12} sx={{padding: width > 500 ? "4%" : "6%", backgroundColor: "#6897FF", borderRadius: "10px", marginTop: "30px"}}>
                            <Typography variant="subtitle1" align="center" sx={{fontFamily: "Montserrat", fontWeight: "bold", color: "white"}}>
                                Please note: All appointment changes and cancellations must be made by phone and will not be accepted through this form
                            </Typography>
                            <div style={{display: "flex", justifyContent: "center", paddingLeft: "5%", paddingRight: "5%"}}>
                                {(firstNameError || lastNameError || emailError || phoneError || existingPatientError) &&
                                    <div style={{display: "flex", flexDirection: "row", alignItems: "center", marginTop: "10px", marginBottom: "10px"}}>
                                        <NewReleasesIcon sx={{color: "#FCB48C"}} />
                                        <Typography variant="subtitle2" sx={{color: "#FCB48C", fontFamily: "Montserrat", marginLeft: "10px"}}>
                                            There was an error with one of the above fields
                                        </Typography>
                                    </div>
                                }
                            </div>
                            <div style={{marginTop: "20px", display: "flex", justifyContent: "center"}}>
                                <Button onClick={handleSubmit} variant="standard" sx={{borderRadius: "15px", backgroundColor: "#fa7167", fontFamily: "Montserrat", fontSize: "14px", fontWeight: "bold", color: "white", padding: "3%", "&:hover": {backgroundColor: "#FF6155"}}}>
                                    Submit
                                </Button>
                                <Button onClick={handleClear} variant="standard" sx={{borderRadius: "15px", marginLeft: "20px", fontFamily: "Montserrat", fontSize: "14px", fontWeight: "bold", color: "#F8DE57", border: "2px solid #F8DE57", padding: "3%"}}>
                                    Clear
                                </Button>
                            </div>
                            {appointmentRequested &&
                                <div style={{marginTop: "30px", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"}}>
                                    <CheckIcon sx={{color: "#64EF93", fontSize: "30px"}} />
                                    <Typography align="center" variant={width > 1600 ? "h6" : "subtitle1"}  sx={{marginTop: "10px", fontFamily: "Montserrat", fontWeight: "bold", color: "#64EF93"}}>
                                        Your appointment request has been submitted, we will get back to you as soon as possible!
                                    </Typography>
                                </div>
                            }   
                        </Grid>
                    </Grid>
                }
            </Grid>
            


            {/* NavBar and Footer Section */}
            <Footer />
            <NavBar />
        </div>
    )
}

export default Appointment;