import ContextProvider from "./Context/WidthContext"
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Index from "./Pages/index";
import Services from "./Pages/services";
import Technology from "./Pages/technology";
import Gallery from "./Pages/gallery";
import StudentProgram from "./Pages/studentProgram";
import Appointment from "./Pages/appointment";
import Contact from "./Pages/contact";
import NotFound from "./Pages/NotFound";

const App = () => {
	return (
		<Router>
			<ContextProvider>
				<Routes>
					<Route exact path="/" element={<Index />} />
					<Route path="/services" element={<Services />} />
					<Route path="/technology" element={<Technology />} />
					<Route path="/gallery" element={<Gallery />} />
					<Route path="/studentprogram" element={<StudentProgram />} />
					<Route path="/appointment" element={<Appointment />} />
					<Route path="/contact" element={<Contact />} />
					<Route path="*" element={<NotFound />} />
				</Routes>
			</ContextProvider>
		</Router>
	)
}

export default App;
