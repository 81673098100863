import React from "react";
import NavBar from "../Components/NavBar";
import Footer from "../Components/Footer";
import { Grid, Typography } from "@mui/material";
import { useWidth } from "../Context/WidthContext";
import intraOralCameraJPEG from "../Images/technologyPage/intraOralCamera.jpeg";


const Technology = () => {
    const { width } = useWidth();

    // Forces the page to start at the top if the page is loaded
    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    return (
		<div style={{position: "absolute", top: "0", left: "0", width: "100%", overflowX: "hidden"}}>
            {/* Landing Page Full Screen */}
            {width > 600 ?
                <Grid container sx={{paddingTop: width > 1700 && "40px", paddingBottom: width > 1700 ? "100px" : "80px", marginTop: width > 1700 ? "100px" : width > 1500 ? "140px" : width > 1300 ? "110px" : "140px", paddingLeft: "7%", paddingRight: width > 1100 ? "7%" : width > 1000 ? "4%" : "7%"}}>
                    <Grid item xs={width > 1000 ? 6 : 12} sx={{display: "flex", alignItems: "flex-end", justifyContent: "center", flexDirection: "column"}}>
                        <div>
                            <Typography variant={width > 1500 ? "subtitle1" : "subtitle2"} align={width > 1000 ? "left" : "center"} sx={{fontFamily: "Montserrat", marginTop: "15px"}}>
                                Our constant investment in technology reflects our commitment to patient care. At Guelph Dental, we have the most up-to-date technology in terms of digital technology and sophisticated dental equipment. We are a fully-digital and hi-tech dental clinic equipped with the latest technology providing very high standards of dental care, safety and comfort in terms of diagnosis, treatment, sterilization, radiation, and record keeping.
                            </Typography>
                            <Typography variant={width > 1500 ? "subtitle1" : "subtitle2"} align={width > 1000 ? "left" : "center"} sx={{fontFamily: "Montserrat", marginTop: "15px"}}>
                                Technology at Guelph Dental is not just limited to regular procedures of removing tooth decay or restoring cavities but includes various advanced dental solutions and procedures that take dentistry at our practice to a higher standard.
                            </Typography>
                            <Grid container sx={{display: "flex", justifyContent: width > 1000 ? "" : "center", flexDirection: "column", marginTop: "5px"}}>
                                <Typography variant={width > 1500 ? "subtitle1" : "subtitle2"} align={width > 1000 ? "left" : "center"} sx={{fontFamily: "Montserrat", color: "black", marginTop: width > 1000 ? "25px" : "20px"}}>
                                    The digital dental technology at Guelph Dental includes:
                                </Typography>
                                <Typography variant={width > 1500 ? "subtitle1" : "subtitle2"} align={width > 1000 ? "left" : "center"} sx={{fontFamily: "Montserrat", color: "#4F7FEE"}}>
                                    Digital X-Rays
                                </Typography>
                                <Typography variant={width > 1500 ? "subtitle1" : "subtitle2"} align={width > 1000 ? "left" : "center"} sx={{fontFamily: "Montserrat", color: "#4F7FEE"}}>
                                    Digital Panoramic X-Rays
                                </Typography>
                                <Typography variant={width > 1500 ? "subtitle1" : "subtitle2"} align={width > 1000 ? "left" : "center"} sx={{fontFamily: "Montserrat", color: "#4F7FEE"}}>
                                    Intraoral photography
                                </Typography>
                                <Typography variant={width > 1500 ? "subtitle1" : "subtitle2"} align={width > 1000 ? "left" : "center"} sx={{fontFamily: "Montserrat", color: "#4F7FEE"}}>
                                    Dental Microscope
                                </Typography>
                                <Typography variant={width > 1500 ? "subtitle1" : "subtitle2"} align={width > 1000 ? "left" : "center"} sx={{fontFamily: "Montserrat", color: "#4F7FEE"}}>
                                    Hi-Tech Sterilization Machines
                                </Typography>
                                <Typography variant={width > 1500 ? "subtitle1" : "subtitle2"} align={width > 1000 ? "left" : "center"} sx={{fontFamily: "Montserrat", color: "#4F7FEE"}}>
                                    Rotary Endodontics
                                </Typography>
                                <Typography variant={width > 1500 ? "subtitle1" : "subtitle2"} align={width > 1000 ? "left" : "center"} sx={{fontFamily: "Montserrat", color: "#4F7FEE"}}>
                                    Laser Decay Detector
                                </Typography>
                            </Grid>
                        </div>
                    </Grid>
                    <Grid item xs={width > 1000 ? 6 : 12} sx={{display: "flex", justifyContent: width > 1000 ? "flex-end" : "center", alignItems: "center", marginTop: width > 1000 ? "" : "20px"}}>
                        <img src={intraOralCameraJPEG} alt="IntraOralCamera" style={{borderRadius: "10px"}} height={width > 1000 ? "70%" : "100%"} width={width > 1200 ? "80%" : width > 1000 ? "85%" : "70%" } />
                    </Grid>
                </Grid>
            :
            // Landing Page Mobi
                <Grid container sx={{marginTop: "90px", paddingLeft: "4%", paddingRight: "4%"}}>
                    <Grid item xs={12} sx={{backgroundColor: "#c9d7fc", borderRadius: "10px", padding: width > 500 ? "4%" : "6%"}}>
                        <Typography variant="h6" align="center" sx={{fontFamily: "Montserrat", fontWeight: "bold", color: "black"}}>
                            Technology
                        </Typography>
                        <Typography variant="subtitle2" align="center" sx={{fontFamily: "Montserrat", marginTop: "10px", color: "#454545"}}>
                            Our constant investment in technology reflects our commitment to patient care. At Guelph Dental, we have the most up-to-date technology in terms of digital technology and sophisticated dental equipment. We are a fully-digital and hi-tech dental clinic equipped with the latest technology providing very high standards of dental care, safety and comfort in terms of diagnosis, treatment, sterilization, radiation, and record keeping.
                        </Typography>    
                        <Typography variant="subtitle2" align="center" sx={{fontFamily: "Montserrat", marginTop: "10px", color: "#454545", paddingBottom: "10px"}}>
                            Technology at Guelph Dental is not just limited to regular procedures of removing tooth decay or restoring cavities but includes various advanced dental solutions and procedures that take dentistry at our practice to a higher standard.
                        </Typography>    
                    </Grid>
                    <Grid item xs={12} sx={{marginTop: "30px", borderRadius: "10px", paddingBottom: "30px"}}>
                        <img src={intraOralCameraJPEG} alt="IntraOralCamera" height="100%" width="100%" style={{borderRadius: "10px"}} />
                    </Grid>
                </Grid>
            }



            {/* NavBar and Footer Sections */}
            <Footer />
            <NavBar />
        </div>
    )
}

export default Technology;