import React from "react";
import NavBar from "../Components/NavBar";
import Footer from "../Components/Footer";
import { useWidth } from "../Context/WidthContext";
import { Grid, Typography } from "@mui/material";
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import cleaningBefore from "../Images/GalleryPage/cleaningBefore.png";
import cleaningAfter from "../Images/GalleryPage/cleaningAfter.png";
import whiteningBefore from "../Images/GalleryPage/whiteningBefore.jpg";
import whiteningAfter from "../Images/GalleryPage/whiteningAfter.jpg";
import crownBefore from "../Images/GalleryPage/crownBefore.jpg";
import crownAfter from "../Images/GalleryPage/crownAfter.jpg";
import bridgeBefore from "../Images/GalleryPage/bridgeBefore.jpg";
import bridgeAfter from "../Images/GalleryPage/bridgeAfter.jpg";
import implantsBefore from "../Images/GalleryPage/implantsBefore.png";
import implantsAfter from "../Images/GalleryPage/implantsAfter.png";
import veenerBefore from "../Images/GalleryPage/veenerBefore.jpg";
import veenerAfter from "../Images/GalleryPage/veenerAfter.jpg";


const Gallery = () => {
    const { width } = useWidth();

    // Forces the page to start at the top if the page is loaded
    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    

    return (
		<div style={{position: "absolute", top: "0", left: "0", width: "100%", overflowX: "hidden"}}>
            <div style={{marginTop: width > 1600 ? "180px" : width > 1300 ? "160px" : width > 1000 ? "140px" : width > 600 ? "120px" : "90px"}} />
                {galleryArray.map((instance, ID) => (
                    // Full Screen Section
                    width > 600 ? 
                        <Grid key={ID} container sx={{marginTop: ID === 0 ? width > 1000 ? "90px" : "80px" : width > 1600 ? "110px" : width > 1300 ? "100px" : width > 1000 ? "90px" : "70px", paddingLeft: width > 1300 ? "10%" : "8%", paddingRight: width > 1300 ? "10%" : "8%", marginBottom: ID === 5 ? width > 1000 ? "144px" : "100px" : ""}}>
                            <Grid item xs={12} sx={{paddingLeft: width > 1000 ? "" : "6%", paddingRight: width > 1000 ? "" : "6%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
                                <Typography variant={width > 1600 ? "h4" : width > 1300 ? "h5" : "h6"} align={width > 1000 ? "left" : "center"} sx={{fontFamily: "Montserrat", fontWeight: "bold"}}>
                                    {instance.header}
                                </Typography>

                                <Grid container sx={{display: "flex", justifyContent: width > 1000 ? "center" : "center", alignItems: "center", marginTop: width > 1600 ? "30px" : width > 1300 ? "20px" : "5px", width: width > 1600 ? "700px" : width > 1300 ? "600px" : "500px", height: "140px"}}>
                                    <Grid item xs={5} sx={{backgroundColor: "#D1E0FF", padding: "2%", borderRadius: "10px", display: "flex", alignItems: "center", position: "relative"}}>
                                        <img src={instance.before} alt="" height="auto" width="100%" style={{borderRadius: "10px", marginTop: "20px"}} />
                                        <Typography sx={{position: "absolute", top: "0", fontWeight: "bold", marginTop: "7px", marginLeft: "7px", fontFamily: "Montserrat"}}>
                                            Before
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={1} sx={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                                        <ArrowCircleRightIcon sx={{color: "#95B8FF"}} />
                                    </Grid>
                                    <Grid item xs={5} sx={{backgroundColor: "#D1E0FF", padding: "2%", borderRadius: "10px", display: "flex", alignItems: "center", position: "relative"}}>
                                        <img src={instance.after} alt="" height="auto" width="100%" style={{borderRadius: "10px", marginTop: "20px"}} />
                                        <Typography sx={{position: "absolute", top: "0", fontWeight: "bold", marginTop: "7px", marginLeft: "7px", fontFamily: "Montserrat"}}>
                                            After
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <hr style={{border: "1px solid #D2E4EE", width: width > 1000 ? "80%" : "90%", marginTop: width > 1600 ? "40px" : width > 1300 ? "30px" : "20px"}} />
                        </Grid>            
                    :
                    // Mobi Section
                        <Grid key={ID} container sx={{paddingLeft: "4%", paddingRight: "4%", marginTop: "30px", marginBottom: ID === 5 ? "30px" : ""}}>
                            <Grid item xs={12} sx={{padding: width > 500 ? "4%" : "6%", backgroundColor: "#D1E0FF", borderRadius: "10px"}}>
                                <Typography variant="h6" sx={{fontFamily: "Montserrat", fontWeight: "bold", color: "black"}}>
                                    {instance.header}
                                </Typography>

                                <Grid container sx={{marginTop: "50px"}}>
                                    <Grid item xs={5.25} sx={{position: "relative"}}>
                                        <img src={instance.before} alt="" height="100%" width="100%" style={{borderRadius: "10px"}} />
                                        <Typography sx={{marginTop: "-27px", position: "absolute", top: "0", fontWeight: "bold", marginLeft: "4px", fontFamily: "Montserrat", color: "black"}}>
                                            Before
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={1.5} sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                                    </Grid>
                                    <Grid item xs={5.25} sx={{position: "relative"}}>
                                        <img src={instance.after} alt="" height="100%" width="100%" style={{borderRadius: "10px"}} />
                                        <Typography sx={{marginTop: "-27px", position: "absolute", top: "0", fontWeight: "bold", marginLeft: "4px", fontFamily: "Montserrat", color: "black"}}>
                                            After
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    ))
                }

  

            {/* Footer and NavBar Section */}
            <Footer />
            <NavBar />
        </div>
    )
}

export default Gallery;


const galleryArray = [
    { 
        header: "Cleaning", 
        before: cleaningBefore,
        after: cleaningAfter
    },
    {
        header: "Tooth Whitening",
        before: whiteningBefore,
        after: whiteningAfter
    },
    {
        header: "Porcelain Crowns",
        before: crownBefore,
        after: crownAfter,
    },
    {
        header: "Bridge",
        before: bridgeBefore,
        after: bridgeAfter
    },
    {
        header: "Implants",
        before: implantsBefore,
        after: implantsAfter
    },
    {
        header: "Porcelain Veneers",
        before: veenerBefore,
        after: veenerAfter
    }
];