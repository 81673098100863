import React from "react";
import { Button, Grid, IconButton, Typography } from "@mui/material";
import { useWidth } from "../Context/WidthContext";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import NavBar from "../Components/NavBar";
import GoogleMaps from "../Components/GoogleMaps";
import Footer from "../Components/Footer";
import StarIcon from '@mui/icons-material/Star';
import styled from "styled-components";
import test from "../Images/HomePage/test.jpg";
import test2 from "../Images/HomePage/test2.jpg";
import test3 from "../Images/HomePage/test3.jpg";
import test4 from "../Images/HomePage/test4.jpg";
import calendar from "../Images/HomePage/calendar.png"
import bill from "../Images/HomePage/bill.png";
import time from "../Images/HomePage/time.png";
import { FaTooth, FaHeartbeat } from "react-icons/fa"; 
import { BsShieldPlus } from "react-icons/bs";
import { MdOutlineEventAvailable } from "react-icons/md"; 
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import landingHomeMobiJpeg from "../Images/HomePage/landingPageImage.jpeg";
import landingHomeMobiWebp from "../Images/HomePage/landingPageImage.webp";
import home1 from "../Images/HomePage/home1.jpeg";
import home2 from "../Images/HomePage/home2.jpeg";
import home3 from "../Images/HomePage/home3.jpeg";
import home4 from "../Images/HomePage/home4.jpeg";


const Index = () => {
    const { width } = useWidth();
    const navigate = useNavigate();

    // Forces the page to start at the top if the page is loaded
    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    // Controls image number
    const [imageNumber, setImageNumber] = React.useState(0);
    const incrementImageNumber = () => {
        if (imageNumber === 3) {
            setImageNumber(0);
        }
        else {
            setImageNumber(imageNumber + 1);
        }
    }
    const decrementImageNumber = () => {
        if (imageNumber === 0) {
            setImageNumber(3);
        }
        else {
            setImageNumber(imageNumber - 1);
        }
    }

    const [landingImageNumber, setLandingImageNumber] = React.useState(0);
    React.useEffect(() => {
        const interval = setInterval(() => {
            if (landingImageNumber === 3) {
                setLandingImageNumber(0);
            }
            else {
                setLandingImageNumber(landingImageNumber + 1);
            }
            if (imageNumber === 3) {
                setImageNumber(0);
            }
            else {
                setImageNumber(imageNumber + 1);
            }
        }, 5000);

        return () => clearInterval(interval);
    }, [landingImageNumber, imageNumber]);


	return (
		<div style={{position: "absolute", top: "0", left: "0", width: "100%", overflowX: "hidden", backgroundColor: "#ECF3FF"}}> 
            {/* Landing Page */}
            <div style={{position: "relative", display: "flex", justifyContent: width > 900 ? "flex-start" : "center", alignItems: "", width: "100vw", height: "80vh", backgroundColor: "white"}}>
                {width > 1100 ? 
                    <Grid container>
                        <Grid item xs={4.5} sx={{paddingLeft: "80px", paddingRight: width > 1300 ? "" : "30px", marginTop: "30px", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
                            <div>
                                <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: width > 900 ? "" : "center", marginBottom: "17px"}}>
                                    <Typography align={width > 900 ? "left" : "center"} variant={width > 1500 ? "h6" : "subtitle1"} sx={{color: "#8EAEDF", fontFamily: "Montserrat"}}>Family Dentistry located in Guelph, Ontario</Typography>
                                </div>
                                <div style={{display: "flex", justifyContent: width > 900 ? "left" : "center", marginBottom: "18px"}}>
                                    <Typography align={width > 900 ? "left" : "center"} variant={width > 1500 ? "h4" : "h5"} sx={{color: "black", fontFamily: "Montserrat", fontWeight: "bold"}}>Welcome to Guelph Dental</Typography>
                                </div> 
                                <Typography align={width > 900 ? "left" : "center"} variant={width > 1500 ? "h6" : "subtitle1"} sx={{color: "black", fontFamily: "Montserrat", marginBottom: width > 600 ? "30px" : "20px", fontWeight: "bold"}}>New Patients and Same Day <br />Dental Emergencies are Welcome</Typography>
                                <div style={{display: "flex", justifyContent: width > 900 ? "flex-start" : "center"}}>
                                    {width > 600 ?
                                        <>
                                            <Button onClick={() => {navigate("/appointment")}} variant="contained" sx={{textTransform: "none", transition: "0.2s", borderRadius: "5px", fontFamily: "Montserrat", fontSize: width > 1600 ? "18px" : "14px", fontWeight: "bold", color: "white", backgroundColor: "#8EAEDF", "&:hover": {backgroundColor: "#709DE1"}}}>
                                                Book an Appointment
                                            </Button>
                                            <Button onClick={() => {navigate("/contact")}} variant="contained" sx={{textTransform: "none", marginLeft: "28px", transition: "0.2s", borderRadius: "5px", fontFamily: "Montserrat", fontSize: width > 1600 ? "18px" : "14px", fontWeight: "bold", color: "white", backgroundColor: "#8EAEDF", "&:hover": {backgroundColor: "#709DE1"}}}>
                                                Contact Us
                                            </Button>
                                        </>
                                    :
                                        <>
                                            <Button onClick={() => {navigate("/appointment")}} variant="contained" sx={{textTransform: "none", transition: "0.2s", borderRadius: "5px", fontFamily: "Montserrat", fontSize: width > 1600 ? "18px" : "14px", fontWeight: "bold", color: "white", backgroundColor: "#8EAEDF", "&:hover": {backgroundColor: "#709DE1"}}}>
                                                Contact Us
                                            </Button>
                                            <Button onClick={() => {navigate("/appointment")}} variant="contained" sx={{textTransform: "none", marginLeft: "28px", transition: "0.2s", borderRadius: "5px", fontFamily: "Montserrat", fontSize: width > 1600 ? "18px" : "14px", fontWeight: "bold", color: "white", backgroundColor: "#8EAEDF", "&:hover": {backgroundColor: "#709DE1"}}}>
                                                Book an Appointment
                                            </Button>
                                        </>
                                    }
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={7.5} sx={{position: "relative"}}>
                            <FadeImage showPopup={landingImageNumber === 0 ? 1 : 0} src={home1} alt="LandingPageImage1" style={{height: "80vh", objectFit: width > 1200 ? "cover" : "", display: "block", position: "absolute"}} />
                            <FadeImage showPopup={landingImageNumber === 1 ? 1 : 0} src={home2} alt="LandingPageImage2" style={{height: "80vh", objectFit: width > 1200 ? "cover" : "", display: "block", position: "absolute"}} />
                            <FadeImage showPopup={landingImageNumber === 2 ? 1 : 0} src={home3} alt="LandingPageImage3" style={{height: "80vh", objectFit: width > 1200 ? "cover" : "", display: "block", position: "absolute"}} />
                            <FadeImage showPopup={landingImageNumber === 3 ? 1 : 0} src={home4} alt="LandingPageImage4" style={{height: "80vh", objectFit: width > 1200 ? "cover" : "", display: "block", position: "absolute"}} />
                        </Grid> 
                    </Grid>
                :
                    <Grid container sx={{position: "relative", height: "80vh", display: "flex", justifyContent: "center", alignItems: "center"}}>
                        <div style={{position: "absolute"}}>
                            <picture>
                                <source srcSet={landingHomeMobiWebp} type="image/webp" />
                                <source srcSet={landingHomeMobiJpeg} type="image/jpeg" />
                                <img src={landingHomeMobiJpeg} alt="LandingPageImage" style={{height: "80vh", width: "100vw", objectFit: "cover", display: "block"}} />
                            </picture>
                        </div>
                        <div style={{position: "absolute", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", paddingLeft: "20px", paddingRight: "20px"}}>
                            <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: width > 900 ? "center" : "center", marginBottom: "17px"}}>
                                <Typography align={width > 900 ? "left" : "center"} variant={width > 1100 ? "h6" : width > 600 ? "subtitle1" : "subtitle2"} sx={{color: "#8EAEDF", fontFamily: "Montserrat"}}>Family Dentistry located in Guelph, Ontario</Typography>
                            </div>
                            <div style={{display: "flex", justifyContent: width > 900 ? "left" : "center", marginBottom: "18px"}}>
                                <Typography align={width > 900 ? "left" : "center"} variant={width > 1100 ? "h3" : width > 600 ? "h4" : "h5"} sx={{color: "black", fontFamily: "Montserrat", fontWeight: "bold"}}>Welcome to Guelph Dental</Typography>
                            </div> 
                            <Typography align={width > 900 ? "left" : "center"} variant={width > 1100 ? "h5" : width > 600 ? "h6" : "subtitle1"} sx={{color: "black", fontFamily: "Montserrat", marginBottom: width > 600 ? "36px" : "26px", fontWeight: "bold"}}>New Patients and Same Day Dental Emergencies are Welcome</Typography>
                            <div style={{display: "flex", justifyContent: width > 900 ? "flex-start" : "center"}}>
                                {width > 600 ?
                                    <>
                                        <Button onClick={() => {navigate("/appointment")}} variant="contained" sx={{textTransform: "none", transition: "0.2s", borderRadius: "5px", fontFamily: "Montserrat", fontSize: width > 1100 ? "18px" : width > 600 ? "14px" : "12px", fontWeight: "bold", color: "white", backgroundColor: "#8EAEDF", "&:hover": {backgroundColor: "#709DE1"}}}>
                                            Book an Appointment
                                        </Button>
                                        <Button onClick={() => {navigate("/contact")}} variant="contained" sx={{textTransform: "none", marginLeft: "28px", transition: "0.2s", borderRadius: "5px", fontFamily: "Montserrat", fontSize: width > 1100 ? "18px" : width > 600 ? "14px" : "12px", fontWeight: "bold", color: "white", backgroundColor: "#8EAEDF", "&:hover": {backgroundColor: "#709DE1"}}}>
                                            Contact Us
                                        </Button>
                                    </>
                                :
                                    <>
                                        <Button onClick={() => {navigate("/appointment")}} variant="contained" sx={{textTransform: "none", transition: "0.2s", borderRadius: "5px", fontFamily: "Montserrat", fontSize: width > 1100 ? "18px" : width > 600 ? "14px" : "12px", fontWeight: "bold", color: "white", backgroundColor: "#8EAEDF", "&:hover": {backgroundColor: "#709DE1"}}}>
                                            Contact Us
                                        </Button>
                                        <Button onClick={() => {navigate("/appointment")}} variant="contained" sx={{textTransform: "none", marginLeft: "28px", transition: "0.2s", borderRadius: "5px", fontFamily: "Montserrat", fontSize: width > 1100 ? "18px" : width > 600 ? "14px" : "12px", fontWeight: "bold", color: "white", backgroundColor: "#8EAEDF", "&:hover": {backgroundColor: "#709DE1"}}}>
                                            Book an Appointment
                                        </Button>
                                    </>
                                }
                            </div>
                        </div>
                    </Grid>
                }
			</div>



            {/* About Us Section */}
            {width <= 900 && <br />}
			<div id="About" style={{paddingLeft: width > 900 ? "10%" : "8%", paddingRight: width > 900 ? "10%" : "8%", marginTop: width > 900 ? "10vh" : "4vh", marginBottom: width > 900 ? "2vh" : "5vh" }}>
                <Typography variant={width > 1100 ? "h5" : "h6"} align="center" sx={{fontFamily: "Montserrat", fontWeight: "bold",}}>
                    About Us
                </Typography>
                <br />
                <Typography variant={width > 1100 ? "subtitle1" : "subtitle2"} align="center" sx={{fontFamily: "Montserrat"}}>
                    Welcome to our dental office where state-of-the-art technology meets compassionate and unparalleled professional care. We understand the importance of making our clients feel welcome and relaxed. Step into our office, and you will be greeted by a team of professionals who know how to make people feel at ease. Guelph Dental understands that good communication is the foundation of any successful relationship. We go out of our way to ensure that our patients understand their oral condition and the details behind any recommended treatments. We look forward to welcoming you to our practice!
                </Typography>
			</div>



            {/* Gallery */}
            <div style={{marginTop: width > 900 ? "6vh" : "", marginBottom: width > 900 ? "8vh" : "4vh"}}>
                <Grid container sx={{display: "flex", justifyContent: "center", paddingLeft: width > 900 ? "" : "4%", paddingRight: width > 900 ? "" : "4%"}}>
                    <img src={imageNumber === 0 ? test : imageNumber === 1 ? test2 : imageNumber === 2 ? test3 : test4} alt="" height={width > 900 ? "300px" : width > 600 ? "250px" : "200px"} width="auto" style={{borderRadius: "15px", border: "3px solid grey"}} />
                </Grid>
                <Grid container sx={{display: "flex", justifyContent: "center", paddingLeft: width > 900 ? "" : "4%", paddingRight: width > 900 ? "" : "4%"}}>
                    <IconButton onClick={decrementImageNumber}>
                        <NavigateBeforeIcon sx={{color: "grey", fontSize: "28px"}} />
                    </IconButton>
                    <IconButton onClick={() => {setImageNumber(0)}}>
                        <FiberManualRecordIcon sx={{fontSize: "16px", color: imageNumber === 0 && "#8495F2"}} />
                    </IconButton>
                    <IconButton onClick={() => {setImageNumber(1)}}>
                        <FiberManualRecordIcon sx={{fontSize: "16px", color: imageNumber === 1 && "#8495F2"}} />
                    </IconButton>
                    <IconButton onClick={() => {setImageNumber(2)}}>
                        <FiberManualRecordIcon sx={{fontSize: "16px", color: imageNumber === 2 && "#8495F2"}} />
                    </IconButton>
                    <IconButton onClick={() => {setImageNumber(3)}}>
                        <FiberManualRecordIcon sx={{fontSize: "16px", color: imageNumber === 3 && "#8495F2"}} />
                    </IconButton>
                    <IconButton onClick={incrementImageNumber}>
                        <NavigateNextIcon sx={{color: "grey", fontSize: "28px"}} />
                    </IconButton>
                </Grid>
            </div>
            {width > 900 && <hr style={{width: "90%", border: "1px solid #D3E3F5"}} />}
            {width <= 900 && <br />}
            


            {/* Services section */}
            <div style={{marginTop: width > 900 ? "10vh" : "", marginBottom: width > 900 ? "10vh" : "4vh"}}>
                <Grid container sx={{display: "flex", justifyContent: "center", paddingLeft: width > 900 ? "" : "4%", paddingRight: width > 900 ? "" : "4%"}}>
                    <Grid item xs={width > 1500 ? 2.5 : width > 900 ? 3 : 12} sx={{borderRadius: "10px", padding: width > 900 ? "2%" : width > 600 ? "4%" : "7%", backgroundColor: "#D2E3FF", borderBottom: width > 900 ? "2px solid #8495F2" : "", transition: "0.4s", "&:hover": {backgroundColor: "#C2D9FF"}}}>
                        <div style={{display: "flex", justifyContent: "flex-start"}}>
                            <span style={{height: "60px", width: "60px", display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: "#e5f9fb", borderRadius: "20px"}}>
                                <img src={calendar} alt="CalendarIcon" height="40px" width="40px" />
                            </span>
                        </div>
                        <br />
                        {width > 900 && <br />}
                        <Typography variant={width > 1100 ? "h6" : "subtitle1"} align="left" sx={{fontFamily: "Montserrat", fontWeight: "bold"}}>Over 20 Years of Experience</Typography>
                        <br />
                        <Typography variant={width > 1100 ? "subtitle1" : "subtitle2"} align="left" sx={{fontFamily: "Montserrat"}}>
                            Our professional team has over 20 years of experience in dentistry. At Guelph Dental, we strive to make all of our patients feel taken care of, welcome and safe. Your oral health is in very competent hands.
                        </Typography>
                    </Grid>

                    {width > 900 && <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>}
                    <Grid item xs={width > 1500 ? 2.5 : width > 900 ? 3 : 12} sx={{borderRadius: "10px", marginTop: width > 900 ? "" : "30px", padding: width > 900 ? "2%" : width > 600 ? "4%" : "7%", backgroundColor: "#D2E3FF", borderBottom: width > 900 ? "2px solid #8495F2" : "", transition: "0.4s", "&:hover": {backgroundColor: "#C2D9FF"}}}>
                        <div style={{display: "flex", justifyContent: "flex-start"}}>
                            <span style={{height: "60px", width: "60px", display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: "rgba(254, 250, 213, 0.7)", borderRadius: "20px"}}>
                                <img src={bill} alt="BillIcon" height="40px" width="40px" />
                            </span>
                        </div>
                        <br />
                        {width > 900 && <br />}
                        <Typography variant={width > 1100 ? "h6" : "subtitle1"} align="left" sx={{fontFamily: "Montserrat", fontWeight: "bold"}}>Direct insurance Billing</Typography>
                        <br />
                        <Typography variant={width > 1100 ? "subtitle1" : "subtitle2"} align="left" sx={{fontFamily: "Montserrat"}}>
                            Our dental office accepts most insurance options. We provide direct billing services. We want the payment process at Guelph Dental to be as straightforward and convenient as possible.
                        </Typography>
                    </Grid>

                    {width > 900 && <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>}
                    <Grid item xs={width > 1500 ? 2.5 : width > 900 ? 3 : 12} sx={{borderRadius: "10px", marginTop: width > 900 ? "" : "30px", padding: width > 900 ? "2%" : width > 600 ? "4%" : "7%", backgroundColor: "#D2E3FF", borderBottom: width > 900 ? "2px solid #8495F2" : "", transition: "0.4s", "&:hover": {backgroundColor: "#C2D9FF"}}}>
                        <div style={{display: "flex", justifyContent: "flex-start"}}>
                            <span style={{height: "60px", width: "60px", display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: "rgba(197, 203, 245, 0.6)", borderRadius: "20px"}}>
                                <img src={time} alt="TimeIcon" height="40px" width="40px" />
                            </span>
                        </div>
                        <br />
                        {width > 900 && <br />}
                        <Typography variant={width > 1100 ? "h6" : "subtitle1"} align="left" sx={{fontFamily: "Montserrat", fontWeight: "bold"}}>Flexible Scheduling</Typography>
                        <br />
                        <Typography variant={width > 1100 ? "subtitle1" : "subtitle2"} align="left" sx={{fontFamily: "Montserrat"}}>
                            We know it can be tricky to get out of work for a dentist’s appointment. We also know that emergencies can happen at any time. We offer weekend and evening appointments as well as same day emergency appointment to ensure we can accommodate all of our patients.
                        </Typography>
                    </Grid>
                </Grid>
            </div>



            {/* Focus on Section */}
            <div style={{paddingLeft: width > 1100 ? "4%" : width > 900 ? "2%" : "", paddingRight: width > 1100 ? "4%" : width > 900 ? "2%" : "", backgroundColor: "#ECF3FF"}}>
                <br />
                {width <= 900 && <br />}
                <Typography variant={width > 1100 ? "h4" : "h5"} align="center" sx={{fontFamily: "Montserrat", fontWeight: "bold", marginBottom: width > 900 ? "36px" : "28px"}}>
                    We focus on
                </Typography>

                <Grid container sx={{display: "flex", justifyContent: "center", paddingLeft: width > 900 ? "" : "4%", paddingRight: width > 900 ? "" : "4%"}}>
                    <Grid item xs={width > 1500 ? 2 : width > 900 ? 5 : 12} sx={{borderRadius: "10px", padding: width > 1500 ? "1%" : width > 900 ? "2%" : width > 600 ? "4%" : "6%", backgroundColor: "#BFF0D2", borderBottom: width > 900 ? "2px solid #38D174" : "", transition: "0.4s", "&:hover": {backgroundColor: "#A9E9C2"}}}>
                        <br />
                        <div style={{display: "flex", justifyContent: "center"}}>
                            <span style={{height: width > 900 ? "100px" : "90px", width: width > 900 ? "100px" : "90px", display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: "#38D174", borderRadius: "100%"}}>
                                <BsShieldPlus style={{fontSize: width > 900 ? "50px" : "40px", color: "white"}} />
                            </span>
                        </div>
                        <br />
                        <br />
                        <Typography variant={width > 1100 ? "h5" : "h6"} align="center" sx={{fontFamily: "Montserrat", fontWeight: "bold"}}>Safety</Typography>
                        <br />
                        <Typography variant={width > 1100 ? "subtitle1" : "subtitle2"} align="center" sx={{fontFamily: "Montserrat"}}>
                            Our priority is the safety of all patients, ensuring the technology we use is clinically proven to be safe, guaranteeing our patients come to absolutely no harm while providing the best results possible
                        </Typography>
                    </Grid>

                    {width > 900 && <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>}
                    <Grid item xs={width > 1500 ? 2 : width > 900 ? 5 : 12} sx={{borderRadius: "10px", marginTop: width > 900 ? "" : "30px", padding: width > 1500 ? "1%" : width > 900 ? "2%" : width > 600 ? "4%" : "6%", backgroundColor: "#FFF7AF", borderBottom: width > 900 ? "2px solid #EFCD53" : "", transition: "0.4s", "&:hover": {backgroundColor: "#F5EDA3"}}}>
                        <br />
                        <div style={{display: "flex", justifyContent: "center"}}>
                            <span style={{height: width > 900 ? "100px" : "90px", width: width > 900 ? "100px" : "90px", display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: "#F4D872", borderRadius: "100%"}}>
                                <FaTooth style={{fontSize: width > 900 ? "50px" : "40px", color: "white"}} />
                            </span>
                        </div>
                        <br />
                        <br />
                        <Typography variant={width > 1100 ? "h5" : "h6"} align="center" sx={{fontFamily: "Montserrat", fontWeight: "bold"}}>Quality</Typography>
                        <br />
                        <Typography variant={width > 1100 ? "subtitle1" : "subtitle2"} align="center" sx={{fontFamily: "Montserrat"}}>
                            Quality is very important to us as we continue reinforcing our identity as a dentistry that provides high quality results, regardless of the service being performed, in hopes that patients leave Guelph Dental with all expectations being met
                        </Typography>
                    </Grid>

                    {(width <= 1500 && width > 900) && <Grid item xs={12}><br /></Grid>}


                    {width > 1500 && <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>}
                    <Grid item xs={width > 1500 ? 2 : width > 900 ? 5 : 12} sx={{borderRadius: "10px", marginTop: width > 900 ? "" : "30px", padding: width > 1500 ? "1%" : width > 900 ? "2%" : width > 600 ? "4%" : "6%", backgroundColor: "#a9def9", borderBottom: width > 900 ? "2px solid #5E90EB" : "", transition: "0.4s", "&:hover": {backgroundColor: "#93D3F3"}}}>
                        <br />
                        <div style={{display: "flex", justifyContent: "center"}}>
                            <span style={{height: width > 900 ? "100px" : "90px", width: width > 900 ? "100px" : "90px", display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: "#5E90EB", borderRadius: "100%"}}>
                                <FaHeartbeat style={{fontSize: width > 900 ? "50px" : "40px", color: "white"}} />
                            </span>
                        </div>
                        <br />
                        <br />
                        <Typography variant={width > 1100 ? "h5" : "h6"} align="center" sx={{fontFamily: "Montserrat", fontWeight: "bold"}}>Care</Typography>
                        <br />
                        <Typography variant={width > 1100 ? "subtitle1" : "subtitle2"} align="center" sx={{fontFamily: "Montserrat"}}>
                            We deeply care for all our patients, ensuring all services are safely and comfortably performed, reguarly communicating with the patient to assure that there is no discomfort while the procedure is being performed
                        </Typography>
                    </Grid>

                    {width > 900 && <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>}
                    <Grid item xs={width > 1500 ? 2 : width > 900 ? 5 : 12} sx={{borderRadius: "10px", marginTop: width > 900 ? "" : "30px", marginBottom: width > 900 ? "" : "32px", padding: width > 1500 ? "1%" : width > 900 ? "2%" : width > 600 ? "4%" : "6%", backgroundColor: "#e4c1f9", borderBottom: width > 900 ? "2px solid #BC59F8" : "", transition: "0.4s", "&:hover": {backgroundColor: "#DDB3F6"}}}>
                        <br />
                        <div style={{display: "flex", justifyContent: "center"}}>
                            <span style={{height: width > 900 ? "100px" : "90px", width: width > 900 ? "100px" : "90px", display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: "#BC59F8", borderRadius: "100%"}}>
                                <MdOutlineEventAvailable style={{fontSize: width > 900 ? "50px" : "40px", color: "white"}} />
                            </span>
                        </div>
                        <br />
                        <br />
                        <Typography variant={width > 1100 ? "h5" : "h6"} align="center" sx={{fontFamily: "Montserrat", fontWeight: "bold"}}>Availability</Typography>
                        <br />
                        <Typography variant={width > 1100 ? "subtitle1" : "subtitle2"} align="center" sx={{fontFamily: "Montserrat"}}>
                            It is important that we are able to provide required services in a timely manner, making an effort to reply to emails/contact attempts as soon as possible, while providing long daily hours with added flexibility upon request
                        </Typography>
                    </Grid>
                </Grid>
                {width > 900 &&
                    <>
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                    </>
                }
            </div>



            {/* Testimonial Section */}
            <div style={{backgroundColor: "#D1E2FF"}}>
                <br />
                <br />
                <br />
                <br />
                <Typography variant={width > 1100 ? "h4" : "h5"} align="center" sx={{fontFamily: "Montserrat", fontWeight: "bold"}}>Customer Reviews</Typography>
                <br />
                <div style={{display: "flex", justifyContent: "center"}}>
                    <StarIcon sx={{color: "#E7B22C"}} />
                    <StarIcon sx={{color: "#E7B22C"}} />
                    <StarIcon sx={{color: "#E7B22C"}} />
                    <StarIcon sx={{color: "#E7B22C"}} />
                    <StarIcon sx={{color: "#E7B22C"}} />
                </div>
                <br />
                <br />
                <Grid container sx={{display: "flex", justifyContent: "center", paddingLeft: width > 900 ? "" : "4%", paddingRight: width > 900 ? "" : "4%"}}>
                    <Grid component={motion.div} whileHover={{scale: 1.02, transition: {duration: 0.3}}} item xs={width > 1500 ? 2 : width > 900 ? 5 : 12} sx={{borderRadius: "10px", paddingLeft: width > 1500 ? "1%" : width > 1100 ? "2%" : "4%", paddingRight: width > 1500 ? "1%" : width > 1100 ? "2%" : "4%", paddingTop: width > 1500 ? "2%" : "4%", paddingBottom: width > 1500 ? "2%" : "4%", boxShadow: "2px 2px 5px 5px rgb(10, 70, 144, 0.2)"}}>
                        <Typography variant="h5" align="center" sx={{fontFamily: "Montserrat", fontWeight: "bold"}}>
                            Shawn A.
                        </Typography>
                        <br />
                        <Typography variant={width > 1100 ? "subtitle1" : "subtitle2"} align="center" sx={{fontFamily: "Montserrat"}}>
                            My family always had the most wonderful experience at this office. All of the staff is highly skilled and knowledgeable. It is also very nice to see your teeth on live screen in front of you and have questions answered. My two kids are actually looking forward to their next visits. I have no problem recommending them to my friends.
                        </Typography>
                    </Grid>

                    {width > 900 && <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>}
                    {width <= 900 && 
                        <Grid item xs={12}>
                            <br />
                            <br />
                        </Grid>
                    }
                    <Grid component={motion.div} whileHover={{scale: 1.02, transition: {duration: 0.3}}} item xs={width > 1500 ? 2 : width > 900 ? 5 : 12} sx={{borderRadius: "10px", paddingLeft: width > 1500 ? "1%" : width > 1100 ? "2%" : "4%", paddingRight: width > 1500 ? "1%" : width > 1100 ? "2%" : "4%", paddingTop: width > 1500 ? "2%" : "4%", paddingBottom: width > 1500 ? "2%" : "4%", boxShadow: "2px 2px 5px 5px rgb(10, 70, 144, 0.2)" }}>
                        <Typography variant="h5" align="center" sx={{fontFamily: "Montserrat", fontWeight: "bold"}}>
                            Roslin P.
                        </Typography>
                        <br />
                        <Typography variant={width > 1100 ? "subtitle1" : "subtitle2"} align="center" sx={{fontFamily: "Montserrat"}}>
                            Dr. James Tang is the best dentist I got to meet so far. unlike any other dentist he doesn't use Anesthesia, and fixed all my cavities in a gently manner.
                        </Typography>
                    </Grid>


                    {(width <= 1500 && width > 900) && <Grid item xs={12}><br /><br /></Grid>}


                    {width > 1500 && <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>}
                    {width <= 900 && 
                        <Grid item xs={12}>
                            <br />
                            <br />
                        </Grid>
                    }
                    <Grid component={motion.div} whileHover={{scale: 1.02, transition: {duration: 0.3}}} item xs={width > 1500 ? 2 : width > 900 ? 5 : 12} sx={{borderRadius: "10px", paddingLeft: width > 1500 ? "1%" : width > 1100 ? "2%" : "4%", paddingRight: width > 1500 ? "1%" : width > 1100 ? "2%" : "4%", paddingTop: width > 1500 ? "2%" : "4%", paddingBottom: width > 1500 ? "2%" : "4%", boxShadow: "2px 2px 5px 5px rgb(10, 70, 144, 0.2)"}}>
                        <Typography variant="h5" align="center" sx={{fontFamily: "Montserrat", fontWeight: "bold"}}>
                            Rossana L.
                        </Typography>
                        <br />
                        <Typography variant={width > 1100 ? "subtitle1" : "subtitle2"} align="center" sx={{fontFamily: "Montserrat"}}>
                            Had my first appointment with Dr. James Tang yesterday. It was a great experience! He clearly cares very much about his work. He took the time to show me all my teeth and explain the images. It was a very positive and fun appointment. Highly recommend him!
                        </Typography>
                    </Grid>

                    {width > 900 && <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>}
                    {width <= 900 && 
                        <Grid item xs={12}>
                            <br />
                            <br />
                        </Grid>
                    }
                    <Grid component={motion.div} whileHover={{scale: 1.02, transition: {duration: 0.3}}} item xs={width > 1500 ? 2 : width > 900 ? 5 : 12} sx={{borderRadius: "10px", paddingLeft: width > 1500 ? "1%" : width > 1100 ? "2%" : "4%", paddingRight: width > 1500 ? "1%" : width > 1100 ? "2%" : "4%", paddingTop: width > 1500 ? "2%" : "4%", paddingBottom: width > 1500 ? "2%" : "4%", boxShadow: "2px 2px 5px 5px rgb(10, 70, 144, 0.2)"}}>
                        <Typography variant="h5" align="center" sx={{fontFamily: "Montserrat", fontWeight: "bold"}}>
                            Jessica G.
                        </Typography>
                        <br />
                        <Typography variant={width > 1100 ? "subtitle1" : "subtitle2"} align="center" sx={{fontFamily: "Montserrat"}}>
                            Best dentist you could find in Guelph, even GTA, patient and knowledgeable, highly recommend!
                        </Typography>
                    </Grid>
                </Grid>
                <br />
                <br />
                <br />
                <br />
                <br />
            </div>



            {/* Contact Section */}
            <div style={{height: "380px", backgroundColor: "#3E50B5", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", paddingRight: "4%", paddingLeft: "4%"}}>
                <Typography variant="h5" align="center" sx={{color: "white", fontFamily: "Montserrat", fontWeight: "bold"}}>
                    Contact Us
                </Typography>

                <Typography variant="subtitl1" align="center" sx={{fontFamily: "Montserrat", color: "white", marginTop: "18px"}}>
                    Please contact us through phone or email for any inquiries!
                </Typography>

                <PhoneAnchor href="tel:(519) 821 - 6668">
                    <Typography variant="subtitle1" align="center" sx={{fontFamily: "Nunito", marginTop: "18px"}}>
                        (519) - 821 - 6668
                    </Typography>
                </PhoneAnchor>

                <Typography variant="subtitl1" align="center" sx={{fontFamily: "Montserrat", color: "white", marginTop: "18px"}}>
                    guelphdental@hotmail.com
                </Typography>
                <Button onClick={() => {navigate("/contact")}} variant="outlined" sx={{marginTop: "36px", transition: "0.4s", color: "white", border: "2px solid white", fontFamily: "Nunito", fontWeight: "bold", "&:hover": {border: "2px solid #FBE732", color: "#FBE732"}}}>
                    Contact
                </Button>
            </div>



            {/* Google Maps, Footer, NavBar Section */}
            <GoogleMaps />            
            <Footer />
            <NavBar />
		</div>
	)
}

export default Index;

const PhoneAnchor = styled.a`
    text-decoration: none;
    color: white;
    &: hover {
        text-decoration: underline;
    }
`;

const FadeImage = styled.img`
    transition: opacity 0.5s;
    opacity: ${({ showPopup }) => (showPopup ? '1' : '0')};    
`;
