import React from "react";
import { Typography, Button } from "@mui/material";
import { useWidth } from "../Context/WidthContext";
import { useNavigate } from "react-router-dom";


const NotFound = () => {
    const { width } = useWidth();
    const navigate = useNavigate();

    // Forces the page to start at the top if the page is loaded
    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    return (
		<div style={{position: "absolute", top: "0", left: "0", width: "100%", overflowX: "hidden", marginLeft: width > 1200 ? "150px" : "", marginTop: "100px", display: width > 1200 ? "inline" : "flex", justifyContent: width > 1200 ? "" : "center", flexDirection: width > 1200 ? "" : "column"}}>
            <div style={{paddingLeft: width > 1200 ? "" : "25px", paddingRight: width > 1200 ? "" : "25px"}}>
                <Typography variant={width > 1500 ? "h6" : "subtitle1"} align={width > 1200 ? "left" : "center"} sx={{fontWeight: "bold", fontFamily: "Montserrat", color: "black", marginTop: width > 1000 ? "25px" : "20px"}}>
                    404 Error
                </Typography>
                <Typography variant={width > 1500 ? "h6" : "subtitle1"} align={width > 1200 ? "left" : "center"} sx={{fontWeight: "bold", fontFamily: "Montserrat", color: "black", marginTop: width > 1000 ? "25px" : "20px"}}>
                    Sorry, you are trying to access a page that does not exist!
                </Typography>
                <Typography variant={width > 1500 ? "subtitle1" : "subtitle2"} align={width > 1200 ? "left" : "center"} sx={{fontFamily: "Montserrat", color: "black", marginTop: "10px"}}>
                    - The Guelph Dental Team
                </Typography>
            </div>
            <div style={{display: width > 1200 ? "" : "flex", justifyContent: width > 1200 ? "" : "center"}}>
                <Button onClick={() => {navigate("/")}} variant="contained" sx={{transition: "0.2s", marginTop: width > 1000 ? "25px" : "20px", borderRadius: "5px", fontFamily: "Montserrat", fontSize: width > 1100 ? "18px" : width > 600 ? "14px" : "12px", fontWeight: "bold", color: "white", backgroundColor: "#8EAEDF", "&:hover": {backgroundColor: "#709DE1"}}}>
                    Back to Home Page
                </Button>
            </div>
        </div>
    );
};

export default NotFound;
