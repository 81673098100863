import { useWidth } from "../Context/WidthContext";

const GoogleMaps = () => {
    const { width } = useWidth();

    return (
        <div>
            <div className="mapouter" style={{height: width > 1500 ? "380px" : width > 1000 ? "330px" : width > 700 ? "300px" : "250px"}}>
                <div className="gmap_canvas">
                    <iframe
                        width="100%"
                        height={width > 1500 ? "380px" : width > 1000 ? "330px" : width > 700 ? "300px" : "250px"}
                        id="gmap_canvas"
                        src="https://maps.google.com/maps?q=64%20Frederick%20Dr&t=&z=13&ie=UTF8&iwloc=&output=embed"
                        frameBorder={0}
                        scrolling="no"
                        marginHeight={0}
                        marginWidth={0}
                        title="GuelphDentalMap"
                    />
                    <br />
                    <style dangerouslySetInnerHTML={{__html: `.mapouter{position:relative;text-align:right;height:${width > 1500 ? "380px" : width > 1000 ? "330px" : width > 700 ? "300px" : "250px"};width:100%;}`}} />
                    <style dangerouslySetInnerHTML={{__html: `.gmap_canvas {overflow:hidden;background:none!important;height:${"380px"};width:100%;}`}} />
                </div>
            </div>
        </div>
    )
}

export default GoogleMaps;