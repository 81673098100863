import React from "react";
import { Box, AppBar, Toolbar, Button, Grid, Menu, MenuItem, IconButton, Typography } from "@mui/material";
import { useWidth } from "../Context/WidthContext";
import { useNavigate, Link } from "react-router-dom";
import MenuIcon from '@mui/icons-material/Menu';
import { Scroll } from "react-fns";
import logo from "../Images/HomePage/logo.png";

const NavBarColor = () => {
    if (window.location.href.charAt(window.location.href.length - 1) === "/") {
        return "#EAF0FF";
    }
    else if (window.location.href.includes("services") || window.location.href.includes("technology") || window.location.href.includes("gallery") || window.location.href.includes("contact")) {
        return "white";
    }
    else if (window.location.href.includes("studentprogram")) {
        return "#ECF3FF";
    }
    else if (window.location.href.includes("appointment")) {
        return "#E5EDFF";
    }
    // Temporary
    else {
        return "transparent";
    }
}

const BoxShadow = (y) => {
    if (window.location.href.charAt(window.location.href.length - 1) === "/") {
        return "";
    }
    else {
        if (y === 0) {
            return "none"
        }
    }
}

const NavBar = () => {
    const { width } = useWidth();
    const navigate = useNavigate();

    const ButtonNameArray = [
        { buttonName: "Home", buttonNavigate: "/" },
        { buttonName: "Services", buttonNavigate: "/services" },
        { buttonName: "Technology", buttonNavigate: "/technology" },
        { buttonName: "Gallery", buttonNavigate: "/gallery" },
        { buttonName: "Student Program", buttonNavigate: "/studentprogram" },
        { buttonName: "Appointments", buttonNavigate: "/appointment" },
        { buttonName: "Contact Us", buttonNavigate: "/contact" },
    ];


    const [menuElement, setMenuElement] = React.useState(null);
    const open = Boolean(menuElement);

    const handleClick = (event) => {
        setMenuElement(event.currentTarget);
    };
    const menuClick = (item) => {
        navigate(item);
        setMenuElement(null);
    };

    
    return (
        <Scroll
            render={({y}) => (
                <Box sx={{transition: "0.3s", flexGrow: 1, position: "fixed", top: "0", left: "0", width: "100%"}}>
                    <AppBar position="static" sx={{paddingTop: width > 1000 ? "10px" : "5px", paddingBottom: width > 1000 ? "10px" : "5px", transition: "1s", backgroundColor: NavBarColor(), backdropFilter: y > 0 && "blur(3px)", boxShadow: BoxShadow(y)}}>
                        <Toolbar>
                            <Grid container sx={{display: "flex", justifyContent: "center"}}>
                                {/* Full Screen NavBar */}
                                {width > 1000 ? 
                                    <Grid item xs={12} sx={{display: "flex", justifyContent: "center"}}>
                                        {ButtonNameArray.map((currentButton, ID) => {
                                            let active = false;
                                            if (currentButton.buttonNavigate === "/") {
                                                if (window.location.pathname === currentButton.buttonNavigate) {
                                                    active = true;
                                                }
                                            } else {
                                                if (window.location.href.toLowerCase().includes(currentButton.buttonNavigate.toLowerCase())) {
                                                    active = true;
                                                }
                                            }

                                            return (
                                                <React.Fragment key={ID}>
                                                    {currentButton.buttonName === "Home" ? 
                                                        <Button component={Link} to={`${currentButton.buttonNavigate}`} sx={{fontFamily: "Nunito", fontSize: width > 1200 ? "22px" : "16px", fontWeight: "bold", color: "#303192", paddingLeft: "1%", paddingRight: "1%", "&:hover": {color: !active && "#54a6dc", backgroundColor: "transparent"}}}>
                                                            <img src={logo} alt="Logo" height={width > 1200 ? "30px" : "23px"} width="auto" style={{marginRight: "12px"}} />
                                                            <p style={{lineHeight: "0", whiteSpace: "nowrap"}}>GUELPH DENTAL</p>
                                                        </Button>
                                                    :
                                                        <Button component={Link} to={`${currentButton.buttonNavigate}`} sx={{fontFamily: "Nunito", fontSize: width > 1200 ? "16px" : "14px", fontWeight: "bold", color: active ? "#54a6dc" : "#303192", paddingLeft: "1%", paddingRight: "1%", "&:hover": {color: !active && "#54a6dc", backgroundColor: "transparent"}}}>
                                                            {currentButton.buttonName}
                                                        </Button>
                                                    }
                                                </React.Fragment>
                                            )
                                        })}
                                    </Grid>
                                :
                                // Mobi NavBar
                                    <>
                                        <Grid item xs={width > 600 ? 6 : 10}>
                                            <Button onClick={() => {navigate("/")}} sx={{height: "100%", fontFamily: "Nunito", fontSize: "20px", fontWeight: "bold", color: "#0694C3", marginLeft: width > 1500 ? "146.546px" : width > 1200 ? "80px" : "", transition: "0.3s", "&:hover": {color: "#303192", backgroundColor: "transparent"}}}>
                                                <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                                                    <img src={logo} alt="GuelphDentalLogo" height="20px" width="auto" />
                                                    <Typography sx={{fontFamily: "Montserrat", fontWeight: "bold", fontSize: "20px", marginLeft: "15px"}}>GUELPH DENTAL</Typography>
                                                </div>
                                            </Button>
                                        </Grid>
                                        <Grid item xs={width > 600 ? 6 : 2} sx={{display: "flex", justifyContent: "flex-end", marginLeft: width > 1500 ? "-146.546px" : width > 1200 ? "-80px" : ""}}>
                                            <>
                                                <IconButton onClick={handleClick} sx={{"&:hover": {backgroundColor: "transparent"}}}>
                                                    <MenuIcon sx={{fontSize: "32px", color: "#0694C3", transition: "0.3s", "&:hover": {color: "#303192"}}} />
                                                </IconButton>
                                                <Menu anchorEl={menuElement} open={open} onClose={() => {setMenuElement(null);}} disableScrollLock={true}>
                                                    {ButtonNameArray.map((currentButton, ID) => {
                                                        let active = false;
                                                        if (currentButton.buttonNavigate === "/") {
                                                            if (window.location.pathname === currentButton.buttonNavigate) {
                                                                active = true;
                                                            }
                                                        } else {
                                                            if (window.location.href.toLowerCase().includes(currentButton.buttonNavigate.toLowerCase())) {
                                                                active = true;
                                                            }
                                                        }

                                                        return (
                                                            <MenuItem sx={{fontFamily: "Nunito", backgroundColor: active && "#5E90EB", "&:hover": {backgroundColor: !active ? "#BBD0F8": "#497CDA"}}} key={ID} id={ID} onClick={() => {menuClick(currentButton.buttonNavigate)}}>
                                                                {currentButton.buttonName}
                                                            </MenuItem>
                                                        );
                                                    })}
                                                </Menu>
                                            </>
                                        </Grid>
                                    </>
                                }

                            </Grid>
                        </Toolbar>
                    </AppBar>
                </Box>
            )}
        />
    )
}

export default NavBar;