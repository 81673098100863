import React from "react";
import { Typography, Grid } from "@mui/material"
import { useWidth } from "../Context/WidthContext";
import styled from "styled-components"


const Footer = () => {
    const { width } = useWidth();


    return (
        <div style={{backgroundColor: "#4F5664", display: "flex", justifyContent: "center", paddingLeft: "15px", paddingRight: "15px"}}>
            <Grid container sx={{marginTop: "60px", marginBottom: "60px"}}>
                <Grid item xs={12}>
                    <Typography variant="subtitle1" align="center" sx={{color: "white", fontFamily: "Montserrat", fontWeight: "bold"}}>
                        Guelph Dental
                    </Typography>
                </Grid>

                <Grid item xs={width > 900 ? 5.8 : 12} sx={{display: width > 900 ? "flex" : "", alignItems: width > 900 ? "flex-end" : "", flexDirection: width > 900 ? "column" : "", justifyContent: width > 900 ? "flex-start" : ""}}>
                    <br />
                    <Typography variant="subtitle1" align="center" sx={{fontFamily: "Montserrat", color: "white"}}>
                        64 Frederick Dr, Guelph, ON, N1L 0P3
                    </Typography>
                    <div style={{display: "flex", justifyContent: "center"}}>
                        <PhoneAnchor href="tel:(519) 821 - 6668">
                            <Typography variant="subtitle1" align="center" sx={{fontFamily: "Montserrat", color: "white"}}>
                                (519) - 821 - 6668
                            </Typography>
                        </PhoneAnchor>     
                    </div>
                    <Typography variant="subtitle1" align="center" sx={{fontFamily: "Montserrat", color: "white"}}>
                        guelphdental@hotmail.com
                    </Typography>
                </Grid>

                {width > 900 &&
                    <Grid item xs={0.4} />
                }
                <Grid item xs={width > 900 ? 5.8 : 12} sx={{display: width > 900 ? "flex" : "", alignItems: width > 900 ? "flex-start" : "", flexDirection: width > 900 ? "column" : "", justifyContent: width > 900 ? "flex-start" : ""}}>
                    {width <= 900 && <br />}
                    <br />
                    <>
                        <Typography variant="subtitle1" align="center" sx={{fontFamily: "Montserrat", color: "white"}}>
                            Monday: 9AM - 7PM
                        </Typography>
                        <Typography variant="subtitle1" align="center" sx={{fontFamily: "Montserrat", color: "white"}}>
                            Tuesday to Thursday: 9AM - 4PM
                        </Typography>
                        <Typography variant="subtitle1" align="center" sx={{fontFamily: "Montserrat", color: "white"}}>
                            Friday: 8AM - 3PM
                        </Typography>
                        <Typography variant="subtitle1" align="center" sx={{fontFamily: "Montserrat", color: "white"}}>
                            Every Other Saturday: 9AM - 4PM
                        </Typography>
                        <Typography variant="subtitle1" align="center" sx={{fontFamily: "Montserrat", color: "white"}}>
                            Other hours available by appointment
                        </Typography>
                    </>
                </Grid>

                
                <Grid item xs={12}>
                    <br />
                    <br />
                    <br />
                    <Typography variant="subtitle1" align="center" sx={{fontFamily: "Montserrat", color: "white"}}>
                        Copyright © 2022 Guelph Dental - All Rights Reserved.
                    </Typography>
                </Grid>
            </Grid>
        </div>
    )
}

export default Footer;

const PhoneAnchor = styled.a`
    text-decoration: none;
    color: white;
    &: hover {
        text-decoration: underline;
    }
`;
